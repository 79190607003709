import React, {useMemo, useState} from 'react';
import ResultLayout from "../ResultLayout";
import ResultHeading from "../ResultHeading";
import LanguageLabel from "../LanguageLabel";
import {Typography} from "@frog/babel-street-ds-toolkit";
import {getAnnotatedSpans} from "../../utilities/AnnotationUtils";
import Switch from "../Switch";
import {Settings} from "../../config";

const CompoundComponents = ({data, supportedLangs}) => {
    const [componentsShown, setComponentsShown] = useState(true);

    const tokens = useMemo(() => {
        return (Array.isArray(data?.results) ? data.results : [])
            .filter(token => token.components); // Only keep the tokens with components
    }, [data]);

    /** An array of spans representing the displayed document with annotations. */
    const docSpans = useMemo(() => {
        const orig = data?.content || '';
        const components = tokens.map(t => {
            const componentString = t.components.map(c => c.text).join(' ');
            return {
                start: t.startOffset,
                end: t.endOffset,
                text: componentsShown ? componentString : undefined,
                props: {
                    annotationType: 'compoundComponent',
                    isActive: componentsShown,
                    primaryColor: '#A2D6FF',
                    secondaryColor: '#D6EDFC',
                    tooltip: <>
                        <div>Original Text: {t.text}</div>
                        <div>Compound Components: {componentString}</div>
                    </>
                },
            };
        });
        return getAnnotatedSpans(orig, components);
    }, [data, tokens, componentsShown]);

    return <ResultLayout
        addSeparator
        unsupported={supportedLangs.includes(data.language) ? false : {
            tab: 'Compound Components',
            supportedLangs: supportedLangs.map(langCode => Settings.languages[langCode])
        }}
        header={
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <div style={{ display: 'flex', gap: '24px', justifyContent: 'space-between' }}>
                    <ResultHeading
                        heading="Compound Components"
                        subheading="In languages like German, Dutch, and Swedish, breaks down compound words into their individual parts."
                    />
                    <div style={{ flexShrink: 0 }}>
                        {supportedLangs.includes(data.language) && <Switch onChange={(val) => setComponentsShown(val)}/>}
                    </div>
                </div>
                <LanguageLabel wasDetected={data.wasLangDetected} languageCode={data.language}/>
            </div>
        }
        contents={<>
            <div style={{paddingTop: '8px', marginBottom: '10px'}} dir="auto">
                <Typography variant="body2_bold">{data?.title || ''}</Typography>
            </div>
            <div className="documentText" dir="auto">{docSpans}</div>
        </>}
    ></ResultLayout>
}

export default CompoundComponents;
