import React from 'react';
import styles from '../styles/AnnotatedSpan.module.css';
import StyledTooltip from "./StyledTooltip";
import classNames from "classnames";
import WikiTooltip from "./WikiTooltip";

function getClassName(annotationType) {
    switch (annotationType) {
        case 'topic': return styles.topic;
        case 'entity': return styles.entity;
        case 'lemma': return styles.lemma;
        case 'posText': return styles.posText;
        case 'posBucket': return styles.posBucket;
        case 'compoundComponent': return styles.compoundComponent;
        case 'hanReading': return styles.hanReading;
        default: return '';
    }
}

const AnnotatedSpan = ({
    // Content
    text, preIcon, postIcon,
    // Hover-related things
    tooltip, wikiId,
    // Styling
    annotationType, primaryColor, secondaryColor, isActive,
    // Events
    onClick, disabled,
}) => {
    const spanContent = (
        <span className={classNames({
                [styles.annotatedSpan]: true,
                [getClassName(annotationType)]: true,
                [styles.disabled]: disabled,
                [styles.active]: isActive,
                [styles.inactive]: !isActive,
                [styles.clickable]: onClick,
            })}
            style={{ '--primary-color': primaryColor, '--secondary-color': secondaryColor }}
            // This is written weird because if "onClick" gets set to "undefined", the wikiTooltip onClick handler will
            // not work. "onClick" needs to actually not be set here if an onClick handler wasn't defined.
            {...(!disabled && onClick && {onClick})}
        >
            {preIcon}
            {text}
            {postIcon}
        </span>
    );

    return (
        <span>
            {tooltip ? (
                <StyledTooltip title={tooltip}>
                    {spanContent}
                </StyledTooltip>
            ) : wikiId ? (
                <WikiTooltip wikiId={wikiId} alt={text} outerContents={spanContent} />
            ) : (
                spanContent
            )}
        </span>
    );
}

export default AnnotatedSpan;
