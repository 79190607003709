import React from 'react';
import {useTextAnalytics} from "../providers/TextAnalyticsProvider";
import Initial from './DocumentInput/Initial';
import SampleDoc from "./DocumentInput/SampleDoc";
import PasteText from "./DocumentInput/PasteText";
import Overview from "./Analysis/Overview";
import Entities from "./Analysis/Entities";
import Topics from "./Analysis/Topics";
import Sentence from "./Linguistics/Sentence";
import Tokenizer from "./Linguistics/Tokenizer";
import HanReadings from "./Linguistics/HanReadings";
import CompoundComponents from "./Linguistics/CompoundComponents";
import PartsOfSpeech from "./Linguistics/PartsOfSpeech";
import Lemmas from "./Linguistics/Lemmas";
import DataLoader from "./DataLoader";
import {Features} from "../config";

const {
    OVERVIEW, TOPICS, ENTITIES,
    LEMMAS, PARTS_OF_SPEECH, COMPOUND_COMPONENTS, HAN_READINGS, TOKENIZER, SENTENCE
} = Features;

function wrapInDataLoader(component, feature) {
    return <DataLoader
        key={feature}
        feature={feature}
        ResultComponent={component}
    />
}

const Results = () => {
    const { wasDocAnalyzed, tabCategory, documentType, chosenArticleId, analysisTab, linguisticTab } = useTextAnalytics();

    const getDocInputResults = () => {
        switch (documentType) {
            case 'sampleDoc':
                return chosenArticleId == null ? <Initial /> : <SampleDoc />
            case 'pasteText':
                return <PasteText />
            default:
                return <div>An error has occurred</div>; // "Impossible" error
        }
    }

    const getAnalysisResults = () => {
        switch (analysisTab) {
            case OVERVIEW: return wrapInDataLoader(Overview, OVERVIEW);
            case TOPICS: return wrapInDataLoader(Topics, TOPICS);
            case ENTITIES: return wrapInDataLoader(Entities, ENTITIES);
            default: return <div>An error has occurred</div>; // "Impossible" error
        }
    }

    const getLinguisticResults = () => {
        switch (linguisticTab) {
            case LEMMAS: return wrapInDataLoader(Lemmas, LEMMAS);
            case PARTS_OF_SPEECH: return wrapInDataLoader(PartsOfSpeech, PARTS_OF_SPEECH);
            case COMPOUND_COMPONENTS: return wrapInDataLoader(CompoundComponents, COMPOUND_COMPONENTS);
            case HAN_READINGS: return wrapInDataLoader(HanReadings, HAN_READINGS);
            case TOKENIZER: return wrapInDataLoader(Tokenizer, TOKENIZER);
            case SENTENCE: return wrapInDataLoader(Sentence, SENTENCE);
            default: return <div>An error has occurred</div>; // "Impossible" error
        }
    }

    return (
        <div style={{ overflow: 'auto', flex: 1, background: "#FFF" }}>
            {!wasDocAnalyzed
                ? getDocInputResults()
                : tabCategory === 'analysis'
                    ? getAnalysisResults()
                    : getLinguisticResults()
            }
        </div>
    );
}

export default Results;