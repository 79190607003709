import { styled } from '@mui/material/styles';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export const StyledTabs = styled((props) => (
    <Tabs {...props} />
))(({ narrow }) => ({
    '& .MuiTabs-scroller': {
        width: 0,
    },
    '& .MuiTabs-flexContainer': {
        gap: narrow === 'true' ? '0px' : '24px',
    },
    '& .MuiTabs-indicator': {
        backgroundColor: 'var(--med-blue)',
    },
}));

export const StyledTab = styled((props) => (
    <Tab {...props} />
))(() => ({
    color: '#0E1117BF',
    margin: "0 8px",
    maxWidth: 'unset',
    flexGrow: '1',
    fontFamily: 'Simplon Norm',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '1.25px',
    '&.Mui-selected': {
        color: 'var(--med-blue)',
    }
}));
