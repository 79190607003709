
import Chip from "@mui/material/Chip";
import { styled } from '@mui/material/styles';


const StyledChip = styled((props) => (
    <Chip {...props} />
))(() => ({
    '&': {
        color: 'var(--med-blue)',
        backgroundColor: '#388CCD1A',
        gap: '0px',
        padding: '6px 12px',

        fontFamily: 'Noto Sans',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.25px',
    },
    '&:hover': {
        backgroundColor: '#388CCD1A',
    },
    '& .MuiChip-icon': {
        color: 'var(--med-blue)',
        marginLeft: '0px',
        marginRight: '0px',
    },
    '& .MuiChip-label': {
        color: 'var(--med-blue)',
        paddingLeft: '8px',
        paddingRight: '8px',
        marginLeft: '0px',
        marginRight: '0px',
    },
}));

export default StyledChip;
