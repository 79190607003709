
const SvgMoney = () => {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.3981 11.7975C14.3981 13.5375 12.8231 14.94 10.6931 15.2175C10.4381 15.255 10.1756 15.27 9.90559 15.27C9.63559 15.27 9.37309 15.255 9.11809 15.2175C6.98809 14.94 5.41309 13.5375 5.41309 11.7975C5.41309 11.3625 5.76559 11.01 6.20059 11.01C6.63559 11.01 6.98809 11.3625 6.98809 11.7975C6.98809 12.825 8.32309 13.695 9.90559 13.695C11.4881 13.695 12.8231 12.825 12.8231 11.7975C12.8231 11.2725 12.8231 10.1775 9.83059 9.8925C8.73559 9.7875 5.41309 9.465 5.41309 6.42C5.41309 4.68 6.98809 3.27 9.11809 2.9925C9.37309 2.955 9.63559 2.94 9.90559 2.94C10.1756 2.94 10.4381 2.955 10.6931 2.9925C12.8231 3.27 14.3981 4.68 14.3981 6.42C14.3981 6.855 14.0456 7.2075 13.6106 7.2075C13.1756 7.2075 12.8231 6.855 12.8231 6.42C12.8231 5.385 11.4881 4.515 9.90559 4.515C8.32309 4.515 6.98809 5.385 6.98809 6.42C6.98809 6.945 6.98809 8.0325 9.98059 8.325C11.0756 8.43 14.3981 8.745 14.3981 11.7975ZM9.90559 1.5C9.47059 1.5 9.11809 1.8525 9.11809 2.2875V2.9925C9.37309 2.955 9.63559 2.94 9.90559 2.94C10.1756 2.94 10.4381 2.955 10.6931 2.9925V2.2875C10.6931 1.8525 10.3406 1.5 9.90559 1.5ZM9.90559 15.27C9.63559 15.27 9.37309 15.255 9.11809 15.2175V15.885C9.11809 16.32 9.47059 16.6725 9.90559 16.6725C10.3406 16.6725 10.6931 16.32 10.6931 15.885V15.2175C10.4381 15.255 10.1756 15.27 9.90559 15.27Z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgMoney;
