
const SvgCalendar = () => {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.57617 16.5C4.16367 16.5 3.81042 16.3533 3.51642 16.0597C3.22292 15.7657 3.07617 15.4125 3.07617 15V4.5C3.07617 4.0875 3.22292 3.7345 3.51642 3.441C3.81042 3.147 4.16367 3 4.57617 3H5.32617V2.23125C5.32617 2.01875 5.39792 1.84375 5.54142 1.70625C5.68542 1.56875 5.86367 1.5 6.07617 1.5C6.28867 1.5 6.46692 1.57175 6.61092 1.71525C6.75442 1.85925 6.82617 2.0375 6.82617 2.25V3H12.8262V2.23125C12.8262 2.01875 12.8982 1.84375 13.0422 1.70625C13.1857 1.56875 13.3637 1.5 13.5762 1.5C13.7887 1.5 13.9667 1.57175 14.1102 1.71525C14.2542 1.85925 14.3262 2.0375 14.3262 2.25V3H15.0762C15.4887 3 15.8419 3.147 16.1359 3.441C16.4294 3.7345 16.5762 4.0875 16.5762 4.5V15C16.5762 15.4125 16.4294 15.7657 16.1359 16.0597C15.8419 16.3533 15.4887 16.5 15.0762 16.5H4.57617ZM4.57617 15H15.0762V7.5H4.57617V15ZM4.57617 6H15.0762V4.5H4.57617V6Z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgCalendar;
