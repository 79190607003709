
const SvgDownUpArrows = () => {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.86443 4.71155C4.86443 4.31689 5.18733 3.99398 5.58199 3.99398C5.97666 3.99398 6.29956 4.31689 6.29956 4.71155V12.3034L8.36615 10.2368C8.64601 9.96414 9.10525 9.96414 9.37792 10.244C9.65778 10.5167 9.65778 10.9759 9.37792 11.2558L6.09147 14.5422C5.81161 14.8221 5.35237 14.8221 5.07252 14.5422L1.78606 11.2558C1.50621 10.9759 1.50621 10.5167 1.78606 10.244C2.05874 9.96414 2.51798 9.96414 2.79783 10.2368L4.86443 12.3034V4.71155ZM14.8817 10.7965V6.44089L16.9483 8.50748C17.2281 8.78016 17.6874 8.78016 17.96 8.50031C18.2399 8.22763 18.2399 7.76839 17.96 7.48854L14.6736 4.20208C14.3937 3.92222 13.9345 3.92222 13.6546 4.20208L10.3682 7.48854C10.0883 7.76839 10.0883 8.22763 10.3682 8.50031C10.6408 8.78016 11.1001 8.78016 11.3799 8.50748L13.4465 6.44089V14.0328C13.4465 14.4274 13.7694 14.7503 14.1641 14.7503C14.5588 14.7503 14.8817 14.4274 14.8817 14.0328V10.7965Z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgDownUpArrows;
