import React, {useState} from 'react';
import {useTextAnalytics} from "../../providers/TextAnalyticsProvider";
import {Button, Progress} from "@frog/babel-street-ds-toolkit";

const AnalyzeButton = ({content, language, disabled}) => {
    const { analyzeDoc, detectLanguage } = useTextAnalytics();
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async () => {
        let lang = language;
        if (lang === undefined) {
            setIsLoading(() => true);
            try {
                lang = await detectLanguage(content);
            } finally {
                setIsLoading(() => false);
            }
        }
        analyzeDoc(content, lang, language === undefined);
    };

    return (
        <Button color="primary"
                size="medium"
                variant="contained"
                onClick={handleClick}
                disabled={disabled || isLoading}
                startIcon={isLoading ? <Progress color="primary" variant="indeterminate" size={'18px'} /> : undefined}
                style={{
                    boxShadow: '0px 4px 5px 0px #0E111740',
                    margin: '8px 0 8px 8px',
                    width: '153px',
                    color: disabled || isLoading ? '#0E111759' : undefined,
                }}>
            Analyze
        </Button>
    );
}

export default AnalyzeButton;
