import React from "react";
import {Tooltip} from "@frog/babel-street-ds-toolkit";
import WikiPreview from "./WikiPreview";
import {useTextAnalytics} from "../providers/TextAnalyticsProvider";

const WikiTooltip = ({alt, wikiId, outerContents, vertical}) => {
    const { getWikiData } = useTextAnalytics();

    const onClick = () => {
        getWikiData(wikiId).then(data => {
            if (data.wikiUrl) {
                window.open(data.wikiUrl, '_blank');
            } else {
                console.error("Wiki URL was not found.");
            }
        });
    };

    return (
        <Tooltip
            title={<WikiPreview wikiId={wikiId} alt={alt} vertical={vertical} />}
            onClick={onClick}
            arrow
            sx={{
                width: 'unset',
                maxWidth: 'unset',
                padding: '0px',
                background: '#FFF',
                boxShadow: '0px 20px 24px -4px #0E111714',
                '& .MuiTooltip-arrow::before': { background: '#FFF' },
            }}
            slotProps={{
                popper: {
                    sx: { pointerEvents: 'none' },
                    modifiers: [
                        { name: 'offset', options: { offset: [0, -8] } },
                    ],
                },
            }}
        >
            <span style={{ cursor: 'pointer' }}>{outerContents}</span>
        </Tooltip>
    );
}

export default WikiTooltip;
