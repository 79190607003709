import React from 'react';
import {useTextAnalytics} from "../../providers/TextAnalyticsProvider";
import AnalyzeButton from "./AnalyzeButton";
import LanguageLabel from "../LanguageLabel";
import {Typography} from "@frog/babel-street-ds-toolkit";
import ResultLayout from "../ResultLayout";

const SampleDoc = () => {
    const { currentArticle } = useTextAnalytics();

    return (
        <ResultLayout
            header={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                    <LanguageLabel languageCode={currentArticle?.language} wasDetected={false} />
                    <AnalyzeButton content={currentArticle?.content} language={currentArticle?.language} />
                </div>
            }
            contents={
                <>
                    <div style={{ paddingTop: '8px', marginBottom: '10px' }} dir="auto">
                        <Typography variant="body2_bold">{currentArticle?.title || ''}</Typography>
                    </div>
                    <div className="documentText" dir="auto">{currentArticle?.content || ''}</div>
                </>
            }
        ></ResultLayout>
    );
}

export default SampleDoc;
