import axios from 'axios';

const RestUtils = {
    get(url) {
        return axios.get(url);
    },

    post(url, data, config) {
        return axios.post(url, data, config);
    },
}

export default RestUtils;