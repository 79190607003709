import React from 'react';
import {Settings} from "../config";
import {InfoOutlineIcon, Typography} from "@frog/babel-street-ds-toolkit";
import StyledTooltip from "./StyledTooltip";

const LanguageLabel = ({ languageCode, wasDetected }) => {

    const language = (languageCode && languageCode in Settings.languages)
        ? Settings.languages[languageCode]
        : 'unknown';

    return (
        <div>
            <StyledTooltip placement="bottom-start" title="Automatically identifies the language or languages of any text from over 60 possible languages.">
                <span style={{ verticalAlign: "middle", marginRight: '6px' }}>
                    <InfoOutlineIcon style={{ width: '18px', height: '18px' }} />
                </span>
            </StyledTooltip>
            <Typography variant="button_bold" style={{ lineHeight: '31px' }}>Language { wasDetected ? 'Detected' : 'Specified'}: {language}</Typography>
        </div>
    );
}

export default LanguageLabel;
