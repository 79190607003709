
export function getPosDescription(langCode, rawPartOfSpeech) {
    if (!rawPartOfSpeech) return '';

    // Look up the token key. Urdu and Arabic are structured differently than the rest.
    let key;
    if (langCode === 'urd' || langCode === 'ara') {
        const keys = rawPartOfSpeech.split(' ');
        // The "IV" tags aren't the first key, so we need to explicitly look for those.
        if (keys.includes('IV_PASS')) {
            key = 'IV_PASS';
        } else if (keys.includes('IV')) {
            key = 'IV';
        } else {
            key = keys[0];
        }
    } else {
        const finalSegment = rawPartOfSpeech.substring(rawPartOfSpeech.lastIndexOf('['));
        key = finalSegment.substring(2, finalSegment.length-1);
    }

    let subtype = '';
    if (langCode === 'rus' && key === 'NOUN') {
        subtype = getRussianNounSuffix(rawPartOfSpeech);
    } else if (langCode === 'pol') {
        subtype = getPolishSuffix(rawPartOfSpeech);
    }

    const description = posLookup?.[langCode]?.[key];
    return description ? `(${description + subtype})` : '';
}

/**
 * Russian has a one-to-many mapping from nouns to one of 6+ descriptions. The second-to-last tag
 * helps to identify which description to use. It looks something like: "Nom", "NomAcc", "GenDatAbl".
 */
function getRussianNounSuffix(rawPartOfSpeech) {
    let secondToLast;
    const tags = rawPartOfSpeech.split('][+');
    if (tags.length > 2) {
        secondToLast = tags[tags.length - 2];
    }

    let cases = [];
    if (secondToLast) {
        if (secondToLast.includes('Nom'))   { cases.push(posLookup['rus']['NOUN-Nom']); }
        if (secondToLast.includes('Acc'))   { cases.push(posLookup['rus']['NOUN-Acc']); }
        if (secondToLast.includes('Dat'))   { cases.push(posLookup['rus']['NOUN-Dat']); }
        if (secondToLast.includes('Gen'))   { cases.push(posLookup['rus']['NOUN-Gen']); }
        if (secondToLast.includes('Abl'))   { cases.push(posLookup['rus']['NOUN-Abl']); }
        if (secondToLast.includes('Instr')) { cases.push(posLookup['rus']['NOUN-Instr']); }
    }
    return (cases.length === 0) ? '' : `: ${cases.join(' ')} case`;
}

/**
 * Adverbs, Adjectives, Nouns, Pronouns, Finite Verbs, and Verb Participles can all have different variations.
 * This function identifies the variation to the best of our ability, and returns a suffix which is appended
 * to the base key and used when looking up the part of speech description in the polish map below.
 */
function getPolishSuffix(rawPartOfSpeech) {
    let subtype = '';
    if (rawPartOfSpeech.endsWith('[+ADV]')) {
        if      (rawPartOfSpeech.includes('[+Comp]')) { subtype = posLookup['pol']['ADV-Comp']; }
        else if (rawPartOfSpeech.includes('[+Sup+]')) { subtype = posLookup['pol']['ADV-Sup']; }

    } else if (rawPartOfSpeech.endsWith('[+ADJ]')) {
        if      (rawPartOfSpeech.includes('[+Comp]')) { subtype = posLookup['pol']['ADJ-Comp']; }
        else if (rawPartOfSpeech.includes('[+Sup+]')) { subtype = posLookup['pol']['ADJ-Sup']; }

    } else if (rawPartOfSpeech.endsWith('[+NOUN]')) {
        if (rawPartOfSpeech.includes("[+Prop]")) { subtype = posLookup['pol']['NOUN-Prop']; }

    } else if (rawPartOfSpeech.endsWith('[+PRON]')) {
        if      (rawPartOfSpeech.includes("[+Dem]")) { subtype = posLookup['pol']['PRON-Dem']; }
        else if (rawPartOfSpeech.includes("[+Poss]")) { subtype = posLookup['pol']['PRON-Poss']; }

    } else if (rawPartOfSpeech.endsWith('[+VFIN]')) {
        if      (rawPartOfSpeech.includes("[+Pres]")) { subtype = posLookup['pol']['VFIN-Pres']; }
        else if (rawPartOfSpeech.includes("[+PartFut]")) { subtype = posLookup['pol']['VFIN-Fut']; }
    }
    return subtype === '' ? '' : `: ${subtype}`;
}

const posLookup = {
    'ara': {
        ABBREV: 'abbreviation',
        ADJ: 'adjective',
        ADV: 'adverb',
        CONJ: 'conjunction',
        CV: 'verb (imperative)',
        DEM_PRON: 'demonstrative pronoun',
        DET: 'determiner',
        EOS: 'end of sentence',
        EXCEPT_PART: 'exception particle',
        FOCUS_PART: 'focus particle',
        FUT_PART: 'future particle',
        INTERJ: 'interjection',
        INTERROG_PART: 'interrogative particle',
        IV: 'verb (imperfect)',
        IV_PASS: 'verb (passive imperfect)',
        NEG_PART: 'negative particle',
        NON_ARABIC: 'not Arabic script',
        NOUN: 'noun',
        NOUN_PROP: 'proper noun',
        NO_FUNC: 'unknown part of speech',
        NUM: 'numbers (Arabic-Indic numbers, Latin, and text-based cardinal)',
        PART: 'particle',
        PREP: 'preposition',
        PRONOUN: 'pronoun',
        PUNC: 'punctuation',
        PV: 'perfective verb',
        PV_PASS: 'passive perfective verb',
        RC_PART: 'resultative clause particle',
        REL_ADV: 'relative adverb',
        REL_PRON: 'relative pronoun',
        SUB_CONJ: 'subordinating conjunction',
        VERB_PART: 'verbal particle'
    },

    // TODO: Raw data does not come back from Rosette for Chinese, so this is currently unused.
    'zho': {
        A: 'adjective',
        D: 'adverb',
        E: 'idiom/phrase',
        EOS: 'sentence final punctuation',
        F: 'non-derivational affix',
        FOREIGN: 'non-Chinese',
        I: 'interjection',
        J: 'conjunction',
        M: 'onomatope',
        NA: 'abbreviation',
        NC: 'common noun',
        NM: 'measure word',
        NN: 'numeral',
        NP: 'proper noun',
        NR: 'pronoun',
        NT: 'temporal noun',
        OC: 'construction',
        PL: 'particle',
        PR: 'preposition',
        PUNCT: 'non-sentence-final punctuation',
        U: 'unknown',
        V: 'verb',
        W: 'derivational suffix',
        WL: 'direction word',
        WV: 'word element - verb',
        X: 'generic affix',
        XP: 'generic prefix',
        XS: 'generic suffix'
    },

    'ces': {
        ADJ: 'adjective: nominative',
        'adjective: genitive': '[k uvedení] zahradní [slavnosti]',
        'adjective: dative': '[k] veselým [lidem]',
        'adjective: accusative': '[jak zdolat] ekonomické [starosti]',
        'adjective: instrumental': 'první bushovou [zastávkou]',
        'adjective: locative': '[na] druhém [okraji silnice]',
        'adjective: vocative': 'ty mladý [muž]',
        ordinal: '[obsadil] 12. [místo]',
        ADV: 'adverb',
        CLIT: 'clitic',
        CM: 'comma',
        CONJ: 'conjunction',
        DATE: 'date',
        INTJ: 'interjection',
        NOUN: 'noun: nominative',
        'noun: genitive': '[krize] autority státu',
        'noun: dative': '[dostala se k] moci',
        'noun: accusative': '[názory na] privatizaci',
        'noun: instrumental': '[Marx s naprostou] jistotou',
        'noun: locative': '[ve vlastním] zájmu',
        'noun: vocative': '[ty] parlamente',
        'abbreviation, initial, unit': 'v., mudr., km/h, m3',
        NUM_ACC: 'numeral: accusative',
        NUM_DAT: 'numeral: dative',
        NUM_DIG: 'digit',
        NUM_GEN: 'numeral: genitive',
        NUM_INS: 'numeral: instrumental',
        NUM_LOC: 'numeral: locative',
        NUM_NOM: 'numeral: nominative',
        NUM_ROM: 'Roman numeral',
        NUM_VOC: 'numeral: vocative',
        PREP: 'preposition',
        PREPPRON: 'prepositional pronoun',
        PRON_ACC: 'pronoun: accusative',
        PRON_DAT: 'pronoun: dative',
        PRON_GEN: 'pronoun: genitive',
        PRON_INS: 'pronoun: instrumental',
        PRON_LOC: 'pronoun: locative',
        PRON_NOM: 'pronoun: nominative',
        PRON_VOC: 'pronoun: vocative',
        PROP: 'proper noun',
        PTCL: 'particle',
        PUNCT: 'punctuation',
        REFL_ACC: 'reflexive pronoun: accusative',
        REFL_DAT: 'reflexive pronoun: dative',
        REFL_GEN: 'reflexive pronoun: genitive',
        REFL_INS: 'reflexive pronoun: instrumental',
        REFL_LOC: 'reflexive pronoun: locative',
        SENT: 'sentence final punctuation',
        VERB_IMP: 'verb: imperative',
        VERB_INF: 'verb: infinitive',
        VERB_PAP: 'verb: past participle',
        VERB_PRI: 'verb: present indicative',
        VERB_TRA: 'verb: transgressive'
    },

    'nld': {
        ADJA: 'attributive adjective',
        ADJD: 'adverbial or predicative adjective',
        ADV: 'non-adjectival adverb',
        ART: 'article',
        CARD: 'cardinals',
        CIRCP: 'right part of circumposition',
        CM: 'comma',
        CMPDPART: 'right truncated part of compound',
        COMCON: 'comparative conjunction',
        CON: 'co-ordinating conjunction',
        CWADV: 'interrogative adverb or subordinate conjunction',
        DEMDET: 'demonstrative determiner',
        DEMPRO: 'demonstrative pronoun',
        DIG: 'digits',
        INDDET: 'indefinite determiner',
        INDPOST: 'indefinite postdeterminer',
        INDPRE: 'indefinite predeterminer',
        INDPRO: 'indefinite pronoun',
        INFCON: 'infinitive conjunction',
        ITJ: 'interjections',
        NOUN: 'common noun or proper noun',
        ORD: 'ordinals',
        PADJ: 'postmodifying adjective',
        PERS: 'personal pronoun',
        POSDET: 'possessive pronoun',
        POSTP: 'postposition',
        PREP: 'preposition',
        PROADV: 'pronominal adverb',
        PTKA: 'adverb modification',
        PTKNEG: 'negation',
        PTKTE: 'infinitive particle',
        PTKVA: 'separated prefix of pronominal adverb or verb',
        PUNCT: 'other punctuation',
        RELPRO: 'relative pronoun',
        RELSUB: 'relative conjunction',
        SENT: 'sentence final punctuation',
        SUBCON: 'subordinating conjunction',
        SYM: 'symbols',
        VAFIN: 'finite auxiliary verb',
        VAINF: 'infinite auxiliary verb',
        VAPP: 'past participle auxiliary verb',
        VVFIN: 'finite substantive verb',
        VVINF: 'infinitive substantive verb',
        VVPP: 'past participle substantive verb',
        WADV: 'interrogative adverb',
        WDET: 'interrogative or relative determiner',
        WPRO: 'interrogative or relative pronoun'
    },

    'eng': {
        ADJ: '(basic) adjective',
        ADJCMP: 'comparative adjective',
        ADJING: 'adjectival ing-form',
        ADJPAP: 'adjectival past participle',
        ADJPRON: 'pronoun (with determiner) or adjective',
        ADJSUP: 'superlative adjective',
        ADV: '(basic) adverb',
        ADVCMP: 'comparative adverb',
        ADVSUP: 'superlative adverb',
        CARD: 'cardinal (except one )',
        CARDONE: 'cardinal one',
        CM: 'comma',
        COADV: 'coordination adverbs either, neither',
        COORD: 'coordinating conjunction',
        COSUB: 'subordinating conjunction',
        COTHAN: 'conjunction than',
        DET: 'determiner',
        DETREL: 'relative determiner whose',
        INFTO: 'infinitive marker to',
        ITJ: 'interjection',
        MEAS: 'measure abbreviation',
        MONEY: 'currency plus cardinal',
        NOT: 'negation not',
        NOUN: 'common noun',
        NOUNING: 'nominal ing-form',
        ORD: 'ordinal',
        PARTPAST: 'past participle (in subclause)',
        PARTPRES: 'present participle (in subclause), gerund',
        POSS: "possessive suffix 's",
        PREDET: 'pre-determiner such',
        PREP: 'preposition',
        PREPADVAS: 'preposition or adverbial as',
        PRON: '(non-personal) pronoun',
        PRONONE: 'pronoun one',
        PRONPERS: 'personal pronoun',
        PRONREFL: 'reflexive pronoun',
        PRONREL: 'relative pronoun who, whom, whose; which; that',
        PROP: 'proper noun',
        PUNCT: 'punctuation (other than SENT and CM)',
        QUANT: 'quantifier all, any, both, double, each, enough, every, (a) few, half, many, some',
        QUANTADV: 'quantifier or adverb much, little',
        QUANTCMP: 'quantifier or comparative adverb more, less',
        QUANTSUP: 'quantifier or superlative adverb most, least',
        SENT: 'sentence final punctuation',
        TIT: 'title',
        VAUX: 'auxiliary (modal)',
        VBI: 'infinitive or imperative of be',
        VBPAP: 'past participle of be',
        VBPAST: 'past tense of be',
        VBPRES: 'present tense of be',
        VBPROG: 'ing-form of be',
        VDI: 'infinitive of do',
        VDPAP: 'past participle of do',
        VDPAST: 'past tense of do',
        VDPRES: 'present tense of do',
        VDPROG: 'ing-form of do',
        VHI: 'infinitive or imperative of have',
        VHPAP: 'past participle of have',
        VHPAST: 'past tense of have',
        VHPRES: 'present tense of have',
        VHPROG: 'ing-form of have',
        VI: 'verb infinitive or imperative',
        VPAP: 'verb past participle',
        VPAST: 'verb past tense',
        VPRES: 'verb present tense',
        VPROG: 'verb ing-form',
        VS: "verbal 's (short for is or has)",
        WADV: 'interrogative adverb',
        WDET: 'interrogative determiner',
        WPRON: 'interrogative pronoun'
    },

    'fra': {
        ADJ2_INV: 'special number invariant adjective',
        ADJ2_PL: 'special plural adjective',
        ADJ2_SG: 'special singular adjective',
        ADJ_INV: 'number invariant adjective',
        ADJ_PL: 'plural adjective',
        ADJ_SG: 'singular adjective',
        ADV: 'adverb',
        CM: 'comma',
        COMME: 'reserved for the word comme',
        CONJQUE: "reserved for the word que '&nbsp;&nbsp;",
        CONN: 'connector subordinate conjunction',
        COORD: 'coordinate conjunction',
        DET_PL: 'plural determiner',
        DET_SG: 'singular determiner',
        MISC: 'miscellaneous',
        NEG: 'negation particle',
        NOUN_INV: 'number invariant noun',
        NOUN_PL: 'plural noun',
        NOUN_SG: 'singular noun',
        NUM: 'numeral',
        PAP_INV: 'number invariant past participle',
        PAP_PL: 'plural past participle',
        PAP_SG: 'singular past participle',
        PC: 'clitic pronoun',
        PREP: 'preposition (other than à, au, de, du, des)',
        PREP_A: `preposition "à''`,
        PREP_DE: `preposition "de''`,
        PRON: 'pronoun',
        PRON_P1P2: '1st or 2nd person pronoun',
        PUNCT: 'punctuation (other than comma)',
        RELPRO: `relative/interrogative pronoun (except "que'')`,
        SENT: 'sentence final punctuation',
        SYM: 'symbols',
        VAUX_INF: 'infinitive auxiliary',
        VAUX_P1P2: '1st or 2nd person auxiliary verb, any tense',
        VAUX_P3PL: '3rd person plural auxiliary verb, any tense',
        VAUX_P3SG: '3rd person singular auxiliary verb, any tense',
        VAUX_PAP: 'past participle auxiliary',
        VAUX_PRP: 'present participle auxiliary verb',
        VERB_INF: 'infinitive verb',
        VERB_P1P2: '1st or 2nd person verb, any tense',
        VERB_P3PL: '3rd person plural verb, any tense',
        VERB_P3SG: '3rd person singular verb, any tense',
        VERB_PRP: 'present participle verb',
        VOICILA: "reserved for voici , voilà''"
    },

    'deu': {
        ADJA: '(positive) attributive adjective',
        ADJA2: 'comparative attributive adjective',
        ADJA3: 'superlative attributive adjective',
        ADJD: '(positive) predicative or adverbial adjective',
        ADJD2: 'comparative predicative or adverbial adjective',
        ADJD3: 'superlative predicative or adverbial adjective',
        ADV: 'non-adjectival adverb',
        ART: 'article',
        CARD: 'cardinal',
        CIRCP: 'circumposition, right part',
        CM: 'comma',
        COADV: 'adverbial conjunction',
        COALS: 'conjunction als',
        COINF: 'infinitival conjunction',
        COORD: 'coordinating conjunction',
        COP1: 'coordination 1st part',
        COP2: 'coordination 2nd part',
        COSUB: 'subordinating conjunction',
        COWIE: 'conjunction wie',
        DATE: 'date',
        DEMADJ: 'demonstrative adjective',
        DEMDET: 'demonstrative determiner',
        DEMINV: 'invariant demonstrative',
        DEMPRO: 'demonstrative pronoun',
        FM: 'foreign word',
        INDADJ: 'indefinite adjective',
        INDDET: 'indefinite determiner',
        INDINV: 'invariant indefinite',
        INDPRO: 'indefinite pronoun',
        ITJ: 'interjection',
        NOUN: 'common noun, nominalized adjective, nominalized infinitive, or proper noun',
        ORD: 'ordinal',
        PERSPRO: 'personal pronoun',
        POSDET: 'possessive determiner',
        POSPRO: 'possessive pronoun',
        POSTP: 'postposition',
        PREP: 'preposition',
        PREPART: 'preposition article',
        PTKANT: 'sentential particle',
        PTKCOM: 'comparative particle',
        PTKINF: 'particle: infinitival zu',
        PTKNEG: 'particle: negation nicht',
        PTKPOS: 'positive modifier',
        PTKSUP: 'superlative modifier',
        PUNCT: 'other punctuation, bracket',
        REFLPRO: 'reflexive sich',
        RELPRO: 'relative pronoun',
        REZPRO: 'reciprocal einander',
        SENT: 'sentence final punctuation',
        SYMB: 'symbols',
        TRUNC: 'truncated word, (first part of a compound or verb prefix)',
        VAFIN: 'finite auxiliary',
        VAINF: 'auxiliary infinitive',
        VAPP: 'auxiliary past participle',
        VMFIN: 'finite modal',
        VMINF: 'modal infinitive',
        VPREF: 'separated verbal prefix',
        VVFIN: 'finite verb form',
        VVINF: 'infinitive',
        VVIZU: 'infinitive with incorporated zu',
        VVPP: 'past participle',
        WADV: 'interrogative adverb',
        WDET: 'interrogative determiner',
        WINV: 'invariant interrogative',
        WPRO: 'interrogative pronoun'
    },

    'ell': {
        ADJ: 'adjective',
        ADV: 'adverb',
        ART: 'article',
        CARD: 'cardinal',
        CLIT: 'clitic (pronoun)',
        CM: 'comma',
        COORD: 'coordinating conjunction',
        COSUBJ: 'conjunction with subjunctive',
        CURR: 'currency',
        DIG: 'digits',
        FM: 'foreign word',
        FUT: 'future tense particle',
        INTJ: 'interjection',
        ITEM: 'item',
        NEG: 'negation particle',
        NOUN: 'common noun',
        ORD: 'ordinal',
        PERS: 'personal pronoun',
        POSS: 'possessive pronoun',
        PREP: 'preposition',
        PREPART: 'preposition with article',
        PRON: 'pronoun',
        PRONREL: 'relative pronoun',
        PROP: 'proper noun',
        PTCL: 'particle',
        PUNCT: 'punctuation (other than SENT and CM)',
        QUOTE: 'quotation marks',
        SENT: 'sentence final punctuation',
        SUBJ: 'subjunctive particle',
        SUBORD: 'subordinating conjunction',
        SYMB: 'special symbol',
        VIMP: 'verb (imperative)',
        VIND: 'verb (indicative)',
        VINF: 'verb (infinitive)',
        VPP: 'participle'
    },

    // TODO: Raw data does not come back from Rosette for Hebrew, so this is currently unused.
    'heb': {
        adjective: 'adjective',
        adverb: 'adverb',
        conjunction: 'conjunction',
        copula: 'copula',
        existential: 'existential',
        indInf: 'independent infinitive',
        interjection: 'interjection',
        interrogative: 'interrogative',
        modal: 'modal',
        negation: 'negation particle',
        noun: 'common noun',
        numeral: 'numeral',
        participle: 'participle',
        passiveParticiple: 'passive participle',
        preposition: 'preposition or prepositional phrase',
        pronoun: 'pronoun',
        properName: 'proper noun',
        punctuation: 'punctuation',
        quantifier: 'quantifier or determiner',
        title: 'title or honorific',
        unknown: 'unknown',
        verb: 'verb',
        wPrefix: 'prefix'
    },

    'hun': {
        ADJ: '(invariant) adjective',
        ADV: 'adverb',
        ADV_PART: 'adverbial participle',
        ART: 'article',
        AUX: 'auxiliary',
        CM: 'comma',
        CONJ: 'conjunction',
        DEICT_PRON_NOM: 'deictic pronoun: nominative',
        DEICT_PRON_ACC: 'deictic pronoun: accusative',
        DEICT_PRON_CASE: 'deictic pronoun: other case',
        FUT_PART_NOM: 'future participle: nominative',
        FUT_PART_ACC: 'future participle: accusative',
        FUT_PART_CASE: 'future participle: other case',
        GENE_PRON_NOM: 'general pronoun: nominative',
        GENE_PRON_ACC: 'general pronoun: accusative',
        GENE_PRON_CASE: 'general pronoun: other case',
        INDEF_PRON_NOM: 'indefinite pronoun: nominative',
        INDEF_PRON_ACC: 'indefinite pronoun: accusative',
        INDEF_PRON_CASE: 'indefinite pronoun: other case',
        INF: 'infinitive (verb)',
        INTERJ: 'interjection',
        LS: 'list item symbol',
        MEA: 'measure, unit',
        NADJ_NOM: 'noun or adjective: nominative',
        NADJ_ACC: 'noun or adjective: accusative',
        NADJ_CASE: 'noun or adjective: other case',
        NOUN_NOM: 'noun: nominative',
        NOUN_ACC: 'noun: accusative',
        NOUN_CASE: 'noun: other case',
        NUM_NOM: 'numeral: nominative',
        NUM_ACC: 'numeral: accusative',
        NUM_CASE: 'numeral: other case',
        NUM_PRON_NOM: 'numeral pronoun: nominative',
        NUM_PRON_ACC: 'numeral pronoun: accusative',
        NUM_PRON_CASE: 'numeral pronoun: other case',
        NUMBER: 'numerals (digits)',
        ORD_NUMBER: 'ordinal',
        PAST_PART_NOM: 'past participle: nominative',
        PAST_PART_ACC: 'past participle: accusative',
        PAST_PART_CASE: 'past participle: other case',
        PERS_PRON: 'personal pronoun',
        POSTPOS: 'postposition',
        PREFIX: 'prefix',
        PRES_PART_NOM: 'present participle: nominative',
        PRES_PART_ACC: 'present participle: accusative',
        PRES_PART_CASE: 'present participle: other case',
        PRON_NOM: 'pronoun: nominative',
        PRON_ACC: 'pronoun: accusative',
        PRON_CASE: 'pronoun: other case',
        PROPN_NOM: 'proper noun: accusative',
        PROPN_ACC: 'proper noun: other case',
        PROPN_CASE: 'proper noun: nominative',
        PUNCT: 'punctuation (other than SENT or CM)',
        REFL_PRON_NOM: 'reflexive pronoun: accusative',
        REFL_PRON_ACC: 'reflexive pronoun: other case',
        REFL_PRON_CASE: 'reflexive pronoun: nominative',
        REL_PRON_NOM: 'relative pronoun: nominative',
        REL_PRON_ACC: 'relative pronoun: accusative',
        REL_PRON_CASE: 'relative pronoun: other case',
        ROM_NUMBER: 'Roman numeral',
        SENT: 'sentence final punctuation',
        SPEC: 'special string (URL, email)',
        SUFF: 'suffix',
        TRUNC: 'compound part',
        VERB: 'verb'
    },

    'ita': {
        ADJEX: 'proclitic noun modifier ex',
        ADJPL: 'plural adjective',
        ADJSG: 'singular adjective',
        ADV: 'adverb',
        CLIT: 'clitic pronoun or adverb',
        CM: 'comma',
        CONJ: 'conjunction',
        CONNADV: 'adverbial connector',
        CONNCHE: 'relative pronoun or conjunction',
        CONNCHI: 'relative or interrogative pronoun chi',
        DEMPL: 'plural demonstrative',
        DEMSG: 'singular demonstrative',
        DETPL: 'plural determiner',
        DETSG: 'singular determiner',
        DIG: 'digits',
        INTERJ: 'interjection',
        ITEM: 'list item marker',
        LET: 'single letter',
        NPL: 'plural noun',
        NSG: 'singular noun',
        ORDPL: 'plural ordinal',
        ORDSG: 'singular ordinal',
        POSSPL: 'plural possessive',
        POSSSG: 'singular possessive',
        PRECLIT: 'pre-clitic',
        PRECONJ: 'pre-conjunction',
        PREDET: 'pre-determiner',
        PREP: 'preposition',
        PREPARTPL: 'preposition + plural article',
        PREPARTSG: 'preposition + singular article',
        PREPREP: 'pre-preposition',
        PRON: 'pronoun (3rd person singular/plural) sé',
        PRONINDPL: 'plural indefinite pronoun',
        PRONINDSG: 'singular indefinite pronoun',
        PRONINTPL: 'plural interrrogative pronoun',
        PRONINTSG: 'singular interrogative pronoun',
        PRONPL: 'plural personal pronoun',
        PRONREL: 'invariant relative pronoun',
        PRONRELPL: 'plural relative pronoun',
        PRONRELSG: 'singular relative pronoun',
        PRONSG: 'singular personal pronoun',
        PROP: 'proper noun',
        PUNCT: 'other punctuation',
        QUANT: 'invariant quantifier',
        QUANTPL: 'plural quantifier, numbers',
        QUANTSG: 'singular quantifier',
        SENT: 'sentence final punctuation',
        VAUXF: 'finite auxiliary essere or avere',
        VAUXGER: 'gerund auxiliary essere or avere',
        VAUXGER_CLIT: 'gerund auxiliary + clitic',
        VAUXIMP: 'imperative auxiliary',
        VAUXIMP_CLIT: 'imperative auxiliary + clitic',
        VAUXINF: 'infinitive auxiliary essere / avere',
        VAUXINF_CLIT: 'infinitive auxiliary essere / avere + clitic',
        VAUXPPPL: 'plural past participle auxiliary',
        VAUXPPPL_CLIT: 'plural past part. auxiliary + clitic',
        VAUXPPSG: 'singular past participle auxiliary',
        VAUXPPSG_CLIT: 'singular past part. auxiliary + clitic',
        VAUXPRPL: 'plural present participle auxiliary',
        VAUXPRPL_CLIT: 'plural present participle auxiliary + clitic',
        VAUXPRSG: 'singular present participle auxiliary',
        VAUXPRSG_CLIT: 'singular present participle auxiliary + clitic',
        VF: 'finite verb form',
        VF_CLIT: 'finite verb + clitic',
        VGER: 'gerund',
        VGER_CLIT: 'gerund + clitic',
        VIMP: 'imperative',
        VIMP_CLIT: 'imperative + clitic',
        VINF: 'verb infinitive',
        VINF_CLIT: 'verb infinitive + clitic',
        VPPPL: 'plural past participle',
        VPPPL_CLIT: 'plural past participle + clitic',
        VPPSG: 'singular past participle',
        VPPSG_CLIT: 'singular past participle + clitic',
        VPRPL: 'plural present participle',
        VPRPL_CLIT: 'plural present participle + clitic',
        VPRSG: 'singular present participle',
        VPRSG_CLIT: 'singular present participle + clitic'
    },

    // TODO: Raw data does not come back from Rosette for Japanese, so this is currently unused.
    'jpn': {
        AA: 'adnominal adjective',
        AJ: 'normal adjective',
        AN: 'adjectival noun',
        D: 'adverb',
        EOS: 'sentence-final punctuation',
        FP: 'non-derivational prefix',
        FS: 'non-derivational suffix',
        HP: 'honorific prefix',
        HS: 'honorific suffix',
        I: 'interjection',
        J: 'conjunction',
        NC: 'common noun',
        NE: 'noun before numerals',
        NN: 'numeral',
        NP: 'proper noun',
        NR: 'pronoun',
        NU: 'classifier',
        O: 'others',
        PL: 'particle',
        PUNCT: 'punctuation other than end of sentence',
        UNKNOWN: 'unknown',
        V: 'verb',
        V1: 'ichidan verb stem',
        V5: 'godan verb stem',
        VN: 'verbal noun',
        VS: 'suru-verb',
        VX: 'irregular verb',
        WP: 'derivational prefix',
        WS: 'derivational suffix'
    },

    'kor': {
        ADC: 'conjunctive adverb',
        ADV: 'constituent or clausal adverb',
        CO: 'copula',
        DAN: 'configurative or demonstrative adnominal',
        EAN: 'adnominal ending',
        ECS: 'coordinate, subordinate, adverbial, complementizer ending',
        EFN: 'final ending',
        ENM: 'nominal ending',
        EPF: 'pre-final ending (tense, honorific)',
        IJ: 'exclamation',
        NFW: 'word written in foreign characters',
        NNC: 'common noun',
        NNU: 'ordinal or cardinal number',
        NNX: 'dependent noun',
        NPN: 'personal or demonstrative pronoun',
        NPR: 'proper noun',
        PAD: 'adverbial postposition',
        PAN: 'adnominal postposition',
        PAU: 'auxiliary postposition',
        PCA: 'case postposition',
        PCJ: 'conjunctive postposition',
        SCM: 'comma',
        SFN: 'sentence ending marker',
        SLQ: 'left quotation mark',
        SRQ: 'right quotation mark',
        SSY: 'symbol',
        UNK: 'unknown',
        VJ: 'adjective',
        VV: 'verb',
        VX: 'auxiliary predicate',
        XPF: 'prefix',
        XSF: 'suffix',
        XSJ: 'adjectivization suffix',
        XSV: 'verbalization prefix'
    },

    'fas': {
        ADJ: 'adjective',
        ADV: 'adverb',
        CONJ: 'conjunction',
        DET: 'indefinite article/determiner',
        EOS: 'end of sentence indicator',
        INT: 'interjection or exclamation',
        N: 'noun',
        NON_FARSI: 'not Arabic script',
        NPROP: 'proper noun',
        NUM: 'number',
        PART: 'particle',
        PREP: 'preposition',
        PRO: 'pronoun',
        PUNC: 'punctuation, other than end of sentence',
        UNK: 'unknown',
        VERB: 'verb',
        VINF: 'infinitive'
    },

    'pol': {
        'ADV': 'adverb',
        'ADV-Comp': 'comparative adjectival',
        'ADV-Sup': 'superlative adjectival',
        'ADJ': 'adjective',
        'ADJ-Comp': 'comparative',
        'ADJ-Sup': 'superlative',
        CM: 'comma',
        CMPND: 'compound part',
        CONJ: 'conjunction',
        DATE: 'date expression',
        EXCL: 'interjection',
        FRGN: 'foreign material',
        'NOUN': 'noun',
        'NOUN-Prop': 'proper',
        NUM: 'numeral (cardinal)',
        ORD: 'numeral (ordinal)',
        PHRAS: 'phraseology',
        PPERS: 'personal pronoun',
        'PR/AUX': 'pronoun with auxiliary {"span":[{"span":[{"em":[{"#text":"być"}]}]}]}',
        PREFL: 'reflexive pronoun',
        PREL: 'relative pronoun',
        PREP: 'preposition',
        'PRON': 'pronoun',
        'PRON-Dem': 'demonstrative',
        'PRON-Poss': 'possessive',
        PRTCL: 'particle',
        'PT/AUX': 'particle with auxiliary {"span":[{"span":[{"em":[{"#text":"być"}]}]}]}',
        PUNCT: 'punctuation (other than CM or SENT)',
        QVRB: 'quasi-verb',
        SENT: 'sentence final punctuation',
        SYMB: 'symbol',
        TIME: 'time expression',
        VAUX: 'auxiliary',
        'VFIN': 'finite verb form',
        'VFIN-Pres': 'present',
        'VFIN-Fut': 'future',
        VGER: 'gerund',
        VINF: 'infinitive',
        VMOD: 'modal',
        VPRT: 'verb participle',
    },

    'por': {
        ADJ: 'invariant adjective',
        ADJPL: 'plural adjective',
        ADJSG: 'singular adjective',
        ADV: 'adverb',
        ADVCOMP: 'comparison adverb mais and menos',
        AUXBE: 'finite "be" ( ser or estar )',
        AUXBEINF: 'infinitive "be"',
        AUXBEINFPRON: 'infinitive "be" with clitic',
        AUXBEPRON: 'finite "be" with clitic',
        AUXHAV: 'finite "have"',
        AUXHAVINF: 'infinitive "have" ( ter , haver )',
        AUXHAVINFPRON: 'infinitive "have" with clitic',
        AUXHAVPRON: 'finite "have" with clitic',
        CM: 'comma',
        CONJ: '(coordinating) conjunction',
        CONJCOMP: 'comparison conjunction do que',
        CONJSUB: 'subordination conjunction',
        DEMPL: 'plural demonstrative',
        DEMSG: 'singular demonstrative',
        DETINT: 'interrogative or exclamative que',
        DETINTPL: 'plural interrogative determiner',
        DETINTSG: 'singular interrogative determiner',
        DETPL: 'plural definite article',
        DETRELPL: 'plural relative determiner',
        DETRELSG: 'singular relative determiner',
        DETSG: 'singular definite article',
        DIG: 'digit',
        GER: 'gerundive',
        GERPRON: 'gerundive with clitic',
        INF: 'verb infinitive',
        INFPRON: 'infinitive with clitic',
        INTERJ: 'interjection',
        ITEM: 'list item marker',
        LETTER: 'isolated character',
        NEG: 'negation',
        NOUN: 'invariant common noun',
        NPL: 'plural common noun',
        NPROP: 'proper noun',
        NSG: 'singular common noun',
        POSSPL: 'plural possessive',
        POSSSG: 'singular possessive',
        PREP: 'preposition',
        PREPADV: 'preposition + adverb',
        PREPDEMPL: 'preposition + plural demonstrative',
        PREPDEMSG: 'preposition + singular demonstrative',
        PREPDETPL: 'preposition + plural determiner',
        PREPDETSG: 'preposition + singular determiner',
        PREPPRON: 'preposition + pronoun',
        PREPQUANTPL: 'preposition + plural quantifier',
        PREPQUANTSG: 'preposition + singular quantifier',
        PREPREL: 'preposition + invariant relative pronoun',
        PREPRELPL: 'preposition + plural relative pronoun',
        PREPRELSG: 'preposition + singular relative pronoun',
        PRON: 'invariant pronoun',
        PRONPL: 'plural pronoun',
        PRONSG: 'singular pronoun',
        PRONREL: 'invariant relative pronoun',
        PRONRELPL: 'plural relative pronoun',
        PRONRELSG: 'singular relative pronoun',
        PUNCT: 'other punctuation',
        QUANTPL: 'plural quantifier',
        QUANTSG: 'singular quantifier',
        SENT: 'sentence final punctuation',
        SYM: 'symbols',
        VERBF: 'finite verb form',
        VERBFPRON: 'finite verb form with clitic',
        VPP: 'past participle (also adjectival use)'
    },

    'rus': {
        ADJ: 'adjective',
        ADJ_CMP: 'adjective: comparative',
        ADV: 'adverb',
        ADV_CMP: 'adverb: comparative',
        AMOUNT: 'currency + cardinal, percentages',
        CM: 'comma',
        CONJ: 'conjunction',
        DET: 'determiner',
        DIG: 'numerals (digits)',
        FRGN: 'foreign word',
        IREL: 'relative/interrogative pronoun',
        ITJ: 'interjection',
        MISC: '(miscellaneous)',
        'NOUN':       'common noun',
        'NOUN-Nom':   'nominative',
        'NOUN-Acc':   'accusative',
        'NOUN-Dat':   'dative',
        'NOUN-Gen':   'genetive',
        'NOUN-Abl':   'ablative',
        'NOUN-Instr': 'instrumental',
        NUM: 'numerals (spelled out)',
        ORD: 'ordinal',
        PERS: 'personal pronoun',
        PREP: 'preposition',
        PRONADV: 'pronominal adverb',
        PRON: 'pronoun',
        PROP: 'proper noun',
        PTCL: 'particle',
        PTCL_INT: 'introduction particle',
        PTCL_MOOD: 'mood marker',
        PTCL_SENT: 'stand-alone particle',
        PUNCT: 'punctuation (other than CM or SENT)',
        SENT: 'sentence final punctuation',
        SYMB: 'symbol',
        VAUX: 'auxiliary verb',
        VFIN: 'finite verb',
        VGER: 'verb gerund',
        VINF: 'verb infinitive',
        VPRT: 'verp participle'
    },

    'spa': {
        ADJ: 'invariant adjective',
        ADJPL: 'plural adjective',
        ADJSG: 'singular adjective',
        ADV: 'adverb',
        ADVADJ: 'adverb, modifying an adjective',
        ADVINT: 'interrogative adverb',
        ADVNEG: 'negation no',
        ADVREL: 'relative adverb',
        AUX: 'finite auxiliary ser or estar',
        AUXINF: 'infinitive ser , estar',
        AUXINFCL: 'infinitive ser , estar with clitic',
        CM: 'comma',
        COMO: 'reserved for word como',
        CONADV: 'adverbial conjunction',
        CONJ: 'conjunction',
        DETPL: 'plural determiner',
        DETQUANT: 'invariant quantifier',
        DETQUANTPL: 'plural quantifier',
        DETQUANTSG: 'singular quantifier',
        DETSG: 'singular determiner',
        DIG: 'numerals (digits)',
        HAB: 'finite auxiliary haber',
        HABINF: 'infinitive haber',
        HABINFCL: 'infinitive haber with clitic',
        INTERJ: 'interjection',
        ITEM: 'list item marker',
        NOUN: 'invariant noun',
        NOUNPL: 'plural noun',
        NOUNSG: 'singular noun',
        NUM: 'numerals (spelled out)',
        PAPPL: 'past participle, plural',
        PAPSG: 'past participle, singular',
        PREDETPL: 'plural pre-determiner',
        PREDETSG: 'singular pre-determiner',
        PREP: 'preposition',
        PREPDET: 'preposition + determiner',
        PRON: 'pronoun',
        PRONCLIT: 'clitic pronoun',
        PRONDEM: 'demonstrative pronoun',
        PRONINT: 'interrogative pronoun',
        PRONPOS: 'possessive pronoun',
        PRONREL: 'relative pronoun',
        PROP: 'proper noun',
        PUNCT: 'punctuation (other than CM or SENT)',
        QUE: 'reserved for word que',
        SE: 'reserved for word se',
        SENT: 'sentence final punctuation',
        VERBFIN: 'finite verb form',
        VERBIMP: 'verb imperative',
        VERBIMPCL: 'imperative with clitic',
        VERBINF: 'verb infinitive',
        VERBINFCL: 'infinitive with clitic',
        VERBPRP: 'present participle',
        VERBPRPCL: 'present participle with clitic'
    },

    'urd': {
        ADJ: 'adjective',
        ADV: 'adverb',
        CONJ: 'conjunction',
        DET: 'indefinite article/determiner',
        EOS: 'end of sentence indicator',
        INT: 'interjection or exclamation',
        N: 'noun',
        NON_URDU: 'not Arabic script',
        NPROP: 'proper noun',
        NUM: 'number',
        PART: 'particle',
        PREP: 'preposition',
        PRO: 'pronoun',
        PUNC: 'punctuation other than end of sentence',
        UNK: 'unknown',
        VERB: 'verb'
    },
}