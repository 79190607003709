import React, {useEffect, useState} from "react";
import styles from '../styles/WikiPreview.module.css';
import {useTextAnalytics} from "../providers/TextAnalyticsProvider";
import {Progress, Typography} from "@frog/babel-street-ds-toolkit";
import classNames from "classnames";

const WikiPreview = ({alt, wikiId, vertical}) => {
    const { getWikiData } = useTextAnalytics();
    const [data, setData] = useState(null);

    // When this tooltip content is displayed, fetch the wiki data to display.
    useEffect(() => {
        getWikiData(wikiId).then((data) => setData(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // When an image 404's, we just remove it from the DOM rather than show a broken image
    const handleImageError = (img) => {
        img?.parentNode?.removeChild(img);
    }

    return (
        <div className={classNames(styles.wikiPreview, {[styles.vertical]: vertical})}>
            {data ? (
                <>
                    <Typography variant="body2" sx={{ margin: '16px' }}>
                        {!data.summaryText || data.summaryText === '' ? 'No preview available.' : data.summaryText}
                    </Typography>
                    {data.image && data.image !== '' &&
                        <img alt={alt}
                             onError={e => handleImageError(e.target)}
                             src={data.image}
                             style={{ objectFit: 'cover' }} />
                    }
                </>
            ) : (
                <div style={{ textAlign: 'center', margin: '20px' }}>
                    <Typography variant="h6">Loading...</Typography>
                    <Progress color="primary" isLinear variant="indeterminate" sx={{ margin: '10px auto' }}/>
                </div>
            )}
        </div>
    );
}

export default WikiPreview;