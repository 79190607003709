import React from 'react';
import styles from '../styles/ResultLayout.module.css';
import classNames from "classnames";
import Unsupported from "./Unsupported";
import {ChevronCloseUpIcon, Typography} from "@frog/babel-street-ds-toolkit";

/**
 * @param header React components to render in the header.
 * @param contents React components to render in the content area.
 * @param drawer A JS object that, when set, will cause a drawer to be rendered. Should be an object containing a title,
 *     content, a boolean indicating if it's open, and a handler for when the open state gets changed:
 *     { title: 'View Entities', content: <div></div>, isOpen: true, setOpen: () => {} }
 * @param addSeparator Whether to draw a line between the header and contents.
 * @param unsupported When set, the "unsupported" screen is drawn instead of the contents. This JS object should
 *     contain the tab name, and a list of the supported languages: { tab: 'Topics', supportedLangs: ['English'] }
 * @returns {Element}
 * @constructor
 */
const ResultLayout = ({ header, contents, drawer, addSeparator, unsupported }) => {
    return (
        <div style={{ height: '100%', overflow: 'hidden' }}>
            <div className={styles.layoutWrapper}>
                {/* Main body (heading + content) */}
                <div className={styles.layoutBody}>
                    <div className={styles.layoutHeading}>{header}</div>

                    {addSeparator && (<div className={styles.separator} />)}

                    {unsupported
                        ? <Unsupported {...unsupported} />
                        : <div className={classNames({
                            [styles.layoutContents]: true,
                            [styles.withSeparator]: addSeparator,
                            [styles.withDrawer]: drawer,
                        })}>
                            {contents}
                        </div>}
                </div>
            </div>

            {/* Drawer that slides up/down, and overlays on top of the main body. */}
            {!unsupported && drawer && (
                <div className={classNames(styles.layoutDrawer, {[styles.open]: drawer.isOpen})}>
                    <div className={styles.drawerHeading} onClick={() => drawer.setOpen(!drawer.isOpen)}>
                        {!drawer.isOpen && <Typography variant="button_bold" className={styles.drawerTitle}>{drawer.title}</Typography>}
                        <ChevronCloseUpIcon className={styles.drawerIcon} />
                    </div>
                    <div className={styles.drawerContent}>
                        {drawer.content}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ResultLayout;
