import styles from '../styles/Disclaimer.module.css';
import {InfoOutlineIcon, Link, Typography} from "@frog/babel-street-ds-toolkit";
import classNames from "classnames";

const TERMS_OF_USE = "https://developer.babelstreet.com/terms-of-use";
const PRIVACY_POLICY = "https://www.babelstreet.com/privacy";

const Disclaimer = () => {

    return (
        <div className={styles.disclaimer}>
            <div className={classNames(styles.description)}>
                <Typography variant="caption">
                    This system is for demonstration purposes only and is not intended to process Personal Data. No
                    Personal Data (any information relating to an identified or identifiable natural person) is to
                    be entered into this system as it may not have the necessary controls in place. By using this
                    system, you agree that you will not enter any Personal Data into the system and that your use of
                    the system will be governed by the <Link href={TERMS_OF_USE}>Terms of Use</Link> and
                    the <Link href={PRIVACY_POLICY}>Privacy Policy</Link>.
                </Typography>
            </div>
            <div className={styles.tab}>
                <InfoOutlineIcon />
            </div>
        </div>
    );
}

export default Disclaimer;
