import {useTextAnalytics} from "../providers/TextAnalyticsProvider";
import {StyledTab, StyledTabs} from "./StyledTabs";
import {Features} from "../config";

const {
    OVERVIEW, TOPICS, ENTITIES,
    LEMMAS, PARTS_OF_SPEECH, COMPOUND_COMPONENTS, HAN_READINGS, TOKENIZER, SENTENCE
} = Features;

const ANALYSIS_OPTS = [
    { value: OVERVIEW, label: 'Overview' },
    { value: TOPICS, label: 'Topics' },
    { value: ENTITIES, label: 'Entities' },
];
const LINGUISTIC_OPTS = [
    { value: LEMMAS, label: 'Lemmas' },
    { value: PARTS_OF_SPEECH, label: 'Parts of Speech' },
    { value: TOKENIZER, label: 'Tokenizer' },
    { value: SENTENCE, label: 'Sentence' },
    { value: COMPOUND_COMPONENTS, label: 'Compound Components' },
    { value: HAN_READINGS, label: 'Han Readings' },
];

const PageTabs = () => {
    const { linguisticTab, setLinguisticTab, analysisTab, setAnalysisTab, tabCategory } = useTextAnalytics();

    const isLinguistics = tabCategory === 'linguistics';

    const [tabOpts, currentTab, setCurrentTab] = isLinguistics
        ? [LINGUISTIC_OPTS, linguisticTab, setLinguisticTab]
        : [ANALYSIS_OPTS, analysisTab, setAnalysisTab];

    return (
        <div style={{ alignContent: 'flex-end' }}>
            <StyledTabs
                narrow={isLinguistics.toString()}
                value={currentTab}
                onChange={(_, val) => setCurrentTab(val)}
                variant={isLinguistics.toString() ? "scrollable" : undefined}
            >
                {tabOpts.map(tabOpt => (<StyledTab key={tabOpt.value} {...tabOpt} />))}
            </StyledTabs>
        </div>
    );
}

export default PageTabs;
