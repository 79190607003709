import React, {useMemo, useState} from 'react';
import ResultLayout from "../ResultLayout";
import ResultHeading from "../ResultHeading";
import LanguageLabel from "../LanguageLabel";
import {Typography} from "@frog/babel-street-ds-toolkit";
import {getAnnotatedSpans} from "../../utilities/AnnotationUtils";
import Switch from "../Switch";
import {Settings} from "../../config";

const Lemmas = ({supportedLangs, data}) => {

    const [lemmasShown, setLemmasShown] = useState(true);

    const tokens = useMemo(() => {
        return (Array.isArray(data?.results) ? data.results : [])
            .filter(token => token.lemma) // Only keep the tokens with lemmas
            // Fancy apostrophes and words that started sentences were being improperly highlighted as "lemmas" since
            // the "before-and-after" text didn't match. There may be other cases to consider here too.
            .filter(token => token.text.replace(/’/, "'").toLowerCase() !== token.lemma.toLowerCase())
    }, [data]);

    /** An array of spans representing the displayed document with annotations. */
    const docSpans = useMemo(() => {
        const orig = data?.content || '';
        const lemmas = tokens.map(t => {
            return {
                start: t.startOffset,
                end: t.endOffset,
                text: lemmasShown ? t.lemma : undefined,
                props: {
                    annotationType: 'lemma',
                    primaryColor: '#A2D6FF',
                    secondaryColor: '#D6EDFC',
                    isActive: lemmasShown,
                    tooltip: <>
                        <div>Original Text: {t.text}</div>
                        <div>Lemma: {t.lemma}</div>
                    </>
                },
            };
        });
        return getAnnotatedSpans(orig, lemmas);
    }, [data, tokens, lemmasShown]);

    return <ResultLayout
        addSeparator
        unsupported={supportedLangs.includes(data.language) ? false : {
            tab: 'Lemmas',
            supportedLangs: supportedLangs.map(langCode => Settings.languages[langCode])
        }}
        header={
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <div style={{ display: 'flex', gap: '24px', justifyContent: 'space-between' }}>
                    <ResultHeading
                        heading="Lemmas"
                        subheading={`Returns the root form of each word in a document. This includes singular forms of nouns and the unconjugated form of verbs. (${supportedLangs.length} Supported Languages)`}
                    />
                    <div style={{ flexShrink: 0 }}>
                        {supportedLangs.includes(data.language) && <Switch onChange={(val) => setLemmasShown(val)}/>}
                    </div>
                </div>
                <LanguageLabel wasDetected={data.wasLangDetected} languageCode={data.language}/>
            </div>
        }
        contents={<>
            <div style={{paddingTop: '8px', marginBottom: '10px'}} dir="auto">
                <Typography variant="body2_bold">{data?.title || ''}</Typography>
            </div>
            <div className="documentText" dir="auto">{docSpans}</div>
        </>}
    ></ResultLayout>
}

export default Lemmas;
