import React, {Fragment, useCallback, useMemo, useState} from 'react';
import styles from '../../styles/PartsOfSpeech.module.css';
import ResultLayout from "../ResultLayout";
import ResultHeading from "../ResultHeading";
import LanguageLabel from "../LanguageLabel";
import {Button, Typography} from "@frog/babel-street-ds-toolkit";
import {getAnnotatedSpans} from "../../utilities/AnnotationUtils";
import AnnotatedSpan from "../AnnotatedSpan";
import {onlyUnique} from "../../utilities/ArrayUtils";
import {Settings} from "../../config";
import {getPosDescription} from "../../posLookupTables";

const LIGHT = '#FCFCFDBF';
const DARK = '#0E1117BF';

const Buckets = {
    B_NOUN:  { id: 'B_NOUN',  bucketText: "Noun",        primary: '#DDC705', secondary: DARK },
    B_PROPN: { id: 'B_PROPN', bucketText: "Proper Noun", primary: '#95D093', secondary: DARK },

    B_PRON:  { id: 'B_PRON', bucketText: "Pronoun",     primary: '#80D3CA', secondary: DARK },
    B_ADJ:   { id: 'B_ADJ',  bucketText: "Adjective",   primary: '#20998C', secondary: LIGHT },

    B_VERB:  { id: 'B_VERB', bucketText: "Verb",        primary: '#6401A0', secondary: LIGHT },
    B_ADV:   { id: 'B_ADV',  bucketText: "Adverb",      primary: '#6D71C2', secondary: LIGHT },

    B_PART:  { id: 'B_PART', bucketText: 'Participle',  primary: '#7CC6FF', secondary: DARK },
    B_ADP:   { id: 'B_ADP',  bucketText: 'Adposition',  primary: '#388CCD', secondary: LIGHT },

    B_CONJ:  { id: 'B_CONJ', bucketText: 'Conjunction', primary: '#CC1A4C', secondary: LIGHT },
    B_DET:   { id: 'B_DET',  bucketText: 'Determiner',  primary: '#F5856D', secondary: LIGHT },

    B_NUM:   { id: 'B_NUM',  bucketText: 'Number',      primary: '#FFC993', secondary: DARK },
    B_SYM:   { id: 'B_SYM',  bucketText: 'Symbol',      primary: '#FFA347', secondary: DARK },
};

const POS = {
    NOUN:  { bucket: Buckets.B_NOUN,  text: 'Noun' },
    PROPN: { bucket: Buckets.B_PROPN, text: 'Proper Noun' },
    PRON:  { bucket: Buckets.B_PRON,  text: 'Pronoun' },
    ADJ:   { bucket: Buckets.B_ADJ,   text: 'Adjective' },
    VERB:  { bucket: Buckets.B_VERB,  text: 'Verb' },
    AUX:   { bucket: Buckets.B_VERB,  text: 'Auxiliary Verb' },
    ADV:   { bucket: Buckets.B_ADV,   text: 'Adverb' },
    PART:  { bucket: Buckets.B_PART,  text: 'Participle' },
    ADP:   { bucket: Buckets.B_ADP,   text: 'Adposition' },
    CONJ:  { bucket: Buckets.B_CONJ,  text: 'Conjunction' },
    SCONJ: { bucket: Buckets.B_CONJ,  text: 'Subordinating Conjunction' },
    DET:   { bucket: Buckets.B_DET,   text: 'Determiner' },
    NUM:   { bucket: Buckets.B_NUM,   text: 'Number' },
    PUNCT: { bucket: Buckets.B_SYM,   text: 'Punctuation' },
    SYM:   { bucket: Buckets.B_SYM,   text: 'Symbol' },
}

// Each outer array is a column, each inner array is a part of speech in that column.
const drawerCategories = [
    [ 'B_NOUN', 'B_PROPN' ],
    [ 'B_PRON', 'B_ADJ'   ],
    [ 'B_VERB', 'B_ADV'   ],
    [ 'B_PART', 'B_ADP'   ],
    [ 'B_CONJ', 'B_DET'   ],
    [ 'B_NUM',  'B_SYM'   ],
];

const PartsOfSpeech = ({data, supportedLangs}) => {
    const [drawerOpen, setDrawerOpen] = useState(true);

    const tokens = useMemo(() => {
        return (Array.isArray(data?.results) ? data.results : [])
            .filter(token => token.partOfSpeech); // Only keep the tokens with parts of speech
    }, [data]);

    // -- Bucket Data / Methods --
    const [enabledBuckets, setEnabledBuckets] = useState(Object.keys(Buckets));
    const availableBuckets = useMemo(() => tokens.map(e => POS[e.partOfSpeech]?.bucket.id).filter(onlyUnique), [tokens]);
    const toggleBucket = useCallback((bucketId) => {
        const catIndex = enabledBuckets.indexOf(bucketId);
        setEnabledBuckets(catIndex === -1
            ? [...enabledBuckets, bucketId] // Add the bucket
            : enabledBuckets.filter(c => c !== bucketId)); // Remove the bucket
    }, [enabledBuckets]);
    const toggleAllBuckets = () => {
        const allPartsOfSpeech = Object.keys(Buckets);
        setEnabledBuckets(enabledBuckets.length < allPartsOfSpeech.length ? allPartsOfSpeech : []);
    }

    /** An array of spans representing the displayed document with annotations. */
    const docSpans = useMemo(() => {
        const orig = data?.content || '';
        const pos = tokens.map(t => {
            const bucket = POS[t.partOfSpeech]?.bucket;
            const color = bucket?.primary;
            return {
                start: t.startOffset,
                end: t.endOffset,
                disabled: !enabledBuckets.includes(bucket?.id),
                props: {
                    annotationType: 'posText',
                    primaryColor: color,
                    tooltip: <>
                        <div style={{ marginBottom: '16px'}}>
                            <span style={{ borderBottom: `4px solid ${color}` }}>{t.text}</span>
                        </div>
                        <div>{POS[t.partOfSpeech]?.text}</div>
                        <div>{getPosDescription(data.language, t.partOfSpeechRaw)}</div>
                    </>
                },
            };
        });
        return getAnnotatedSpans(orig, pos);
    }, [data, tokens, enabledBuckets]);

    const getBucketSpan = useCallback((bucketId) => {
        const bucket = Buckets[bucketId];
        const isDisabled = !availableBuckets.includes(bucketId);
        return (
            <Fragment key={bucketId}>
                <AnnotatedSpan
                    annotationType="posBucket"
                    primaryColor={bucket.primary}
                    secondaryColor={bucket.secondary}
                    disabled={isDisabled}
                    isActive={enabledBuckets.includes(bucketId)}
                    tooltip={isDisabled ? "This part of speech was not found in the document." : undefined}
                    text={bucket.bucketText}
                    onClick={() => toggleBucket(bucketId)}
                />
            </Fragment>
        );
    }, [availableBuckets, enabledBuckets, toggleBucket]);

    return <ResultLayout
        addSeparator
        unsupported={supportedLangs.includes(data.language) ? false : {
            tab: 'Parts of Speech',
            supportedLangs: supportedLangs.map(langCode => Settings.languages[langCode])
        }}
        header={
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <ResultHeading
                    heading="Parts of Speech"
                    subheading="For all words in document, returns the part of speech (verb, noun, punctuation, etc.). Hover over words to see the specific part of speech (verb past tense, plural noun, superlative, adverb, etc.)."
                />
                <LanguageLabel wasDetected={data.wasLangDetected} languageCode={data.language}/>
            </div>
        }
        contents={<>
            <div style={{paddingTop: '8px', marginBottom: '10px'}} dir="auto">
                <Typography variant="body2_bold">{data?.title || ''}</Typography>
            </div>
            <div className="documentText" dir="auto">{docSpans}</div>
        </>}
        drawer={{
            isOpen: drawerOpen,
            setOpen: (isOpen) => setDrawerOpen(isOpen),
            title: "View/Select Parts of Speech",
            content: (<>
                <div className={styles.drawerFlex}>
                    {drawerCategories.map((bucketId, i) => (
                        <div key={`posColumn-${i}`}>{bucketId.map(id => {
                            return getBucketSpan(id);
                        })}</div>
                    ))}
                </div>
                <Button color="primary" size="medium" variant="ghost" style={{ top: '10px' }} onClick={toggleAllBuckets}>
                    Select/Unselect All
                </Button>
            </>),
        }}
    ></ResultLayout>
}

export default PartsOfSpeech;
