import AnnotatedSpan from "../components/AnnotatedSpan";
import {Fragment} from "react";

/**
 * Each annotation object should have the following keys:
 * - "start": index of the first character inside the provided doc
 * - "end": index of the last character inside the provided doc PLUS ONE.
 * - "text": Optional text which, when set, will be displayed in place of the document text.
 * - "props": Properties passed directly into the AnnotatedSpan component. Used for styling and interaction.
 * - "disabled": When set to true, this annotation is rendered as a standard span instead of an AnnotatedSpan.
 *
 * @param doc The original document text
 * @param annotations A list of annotation objects, each containing the values documented above.
 *      **These should be sorted by their "start" values.**
 */
export function getAnnotatedSpans(doc, annotations) {
    let index = 0;
    let spans = [];
    for (let i = 0; i < annotations.length; i++) {
        const {start, end, text, props, disabled} = annotations[i];
        // Add everything to the start of this range, if needed
        if (index < start) {
            spans.push(<span key={`span-${index}`}>{doc.substring(index, start)}</span>);
        }
        // Add this range
        spans.push(disabled
            ? <span key={`span-${start}`}>{doc.substring(start, end)}</span>
            : <Fragment key={`span-${start}`}><AnnotatedSpan text={text ?? doc.substring(start, end)} {...props} /></Fragment>);
        index = end;
    }
    if (index < doc.length-1) {
        spans.push(<span key={`span-${index}`}>{doc.substring(index)}</span>);
    }

    return spans;
}