import React, {useMemo, useState} from 'react';
import styles from '../../styles/HanReadings.module.css';
import ResultLayout from "../ResultLayout";
import ResultHeading from "../ResultHeading";
import LanguageLabel from "../LanguageLabel";
import {Typography} from "@frog/babel-street-ds-toolkit";
import {getAnnotatedSpans} from "../../utilities/AnnotationUtils";
import Switch from "../Switch";
import {Settings} from "../../config";
import classNames from "classnames";

const HanReadings = ({data, supportedLangs}) => {
    const [readingsShown, setReadingsShown] = useState(true);

    const readings = useMemo(() => {
        return (Array.isArray(data?.results) ? data.results : [])
            .filter(token => token.readings) // Only keep the tokens with readings
            .filter(token => token.text !== token.readings[0])
    }, [data]);

    /** An array of spans representing the displayed document with annotations. */
    const docSpans = useMemo(() => {
        const orig = data?.content || '';
        const readingSpans = readings.map(t => {
            return {
                start: t.startOffset,
                end: t.endOffset,
                text: readingsShown ? t.readings[0] : undefined,
                // Tooltip and styling is only shown when there are multiple readings.
                props: (t.readings.length <= 1 ? undefined : {
                    annotationType: 'hanReading',
                    isActive: readingsShown,
                    primaryColor: '#A2D6FF',
                    secondaryColor: '#D6EDFC',
                    tooltip: <>
                        <div>Original Text: {t.text}</div>
                        <div>Phonetic Readings: {t.readings.join(', ')}</div>
                    </>
                }),
            };
        });
        return getAnnotatedSpans(orig, readingSpans);
    }, [data, readings, readingsShown]);

    return <ResultLayout
        addSeparator
        unsupported={supportedLangs.includes(data.language) ? false : {
            tab: 'Han Readings',
            supportedLangs: supportedLangs.map(langCode => Settings.languages[langCode])
        }}
        header={
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <div style={{ display: 'flex', gap: '24px', justifyContent: 'space-between' }}>
                    <ResultHeading
                        heading="Han Readings"
                        subheading="Provides phonetic transcriptions (pinyin for Chinese and hiragana for Japanese) to help you understand the pronunciation of Chinese and Japanese characters.  Terms with multiple readings are highlighted."
                    />
                    <div style={{ flexShrink: 0 }}>
                        {supportedLangs.includes(data.language) && <Switch onChange={(val) => setReadingsShown(val)}/>}
                    </div>
                </div>
                <LanguageLabel wasDetected={data.wasLangDetected} languageCode={data.language}/>
            </div>
        }
        contents={<>
            <div style={{paddingTop: '8px', marginBottom: '10px'}}>
                <Typography variant="body2_bold">{data?.title || ''}</Typography>
            </div>
            <div className={classNames("documentText", {[styles.withGaps]: readingsShown && data.language === 'zho'})}>{docSpans}</div>
        </>}
    ></ResultLayout>
}

export default HanReadings;
