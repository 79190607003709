import styles from './App.module.css';
import {lightTheme, ThemeProvider,} from '@frog/babel-street-ds-toolkit';
import Library from './components/Library';
import Results from './components/Results';
import {useTextAnalytics} from "./providers/TextAnalyticsProvider";
import DocToggle from "./components/DocToggle";
import CategoryTabs from "./components/CategoryTabs";
import PageTabs from "./components/PageTabs";
import Disclaimer from "./components/Disclaimer";

const App = () => {
  const { wasDocAnalyzed } = useTextAnalytics();

  return (
      <ThemeProvider theme={lightTheme}>
        <div style={{ position: 'relative', marginRight: '20px' }}>
          {/* "Analysis" vs "Linguistics" tabs on the right */}
          { wasDocAnalyzed && <CategoryTabs />}
          <Disclaimer />

          <div className={styles.appLayout}>
            {/* Left Side */}
            <div className={styles.appColumn}>
              <DocToggle />
              <Library />
            </div>

            {/* Right Side */}
            <div className={styles.appColumn} style={{ flex: '3', marginRight: '20px' }}>
              {!wasDocAnalyzed ? <div /> : <PageTabs />}
              <Results />
            </div>
          </div>
        </div>
      </ThemeProvider>
  );
}

export default App;
