import React from 'react';
import {Typography} from "@frog/babel-street-ds-toolkit";
import FullPageInfo from "../FullPageInfo";

const Initial = () => {
    return (
        <FullPageInfo contents={<>
            <Typography variant="h6" sx={{ color: '#388CCD' }}>Experience the Power of Babel Street Text Analytics</Typography>
            <Typography variant="body1_italic" sx={{ maxWidth: '582px', margin: '4px auto 0 auto' }}>
                Discover how Babel Street's AI-driven NLP tools can elevate your search, analysis, and investigation processes. Leverage explainable AI to transform text into actionable insights with ease and accuracy.
            </Typography>
            <Typography variant="body1_bold" sx={{ marginTop: '33px' }}>Get Started in Two Simple Steps:</Typography>

            <div style={{ maxWidth: '476px', margin: '10px auto 0 auto', textAlign: 'left' }}>
                <Typography variant="body1">
                    <span style={{ fontWeight: 'bold', marginRight: '6px' }}>1. Choose your text:</span>
                    Select a provided sample document or paste your own text to analyze.
                </Typography>
                <Typography variant="body1" sx={{ marginTop: '24px' }}>
                    <span style={{ fontWeight: 'bold', marginRight: '6px' }}>2. Reveal Insights:</span>
                    Use our advanced tools to delve into the data, uncover trends, and spot critical patterns.
                </Typography>
            </div>

            <Typography variant="body1_italic" sx={{ marginTop: '33px', color: 'var(--med-blue)' }}>
                Start exploring today!
            </Typography>
        </>} />
    );
}

export default Initial;
