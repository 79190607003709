
const SvgGlobe = () => {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.82617 1.5C5.68617 1.5 2.32617 4.86 2.32617 9C2.32617 13.14 5.68617 16.5 9.82617 16.5C13.9662 16.5 17.3262 13.14 17.3262 9C17.3262 4.86 13.9662 1.5 9.82617 1.5ZM6.81117 12.015C7.06617 13.1025 7.42617 13.9725 7.79367 14.6475C6.45867 14.1675 5.34117 13.2225 4.64367 12.015H6.81117ZM4.01367 7.4925H6.57117C6.51117 7.965 6.48117 8.4675 6.48117 8.9925C6.48117 9.5175 6.51117 10.0425 6.56367 10.515H4.02117C3.89367 10.0275 3.82617 9.525 3.82617 9C3.82617 8.475 3.89367 7.9725 4.01367 7.4925ZM11.5662 7.4925C11.6337 7.965 11.6712 8.46 11.6712 8.9925C11.6712 9.525 11.6412 10.05 11.5812 10.515H8.07117C8.01117 10.05 7.98117 9.54 7.98117 8.9925C7.98117 8.445 8.01867 7.965 8.08617 7.4925H11.5662ZM9.81867 15C9.44367 14.5575 8.77617 13.59 8.36367 12.015H11.2962C10.8762 13.59 10.2087 14.5575 9.84117 15H9.81867ZM12.8412 12.015H15.0087C14.3112 13.2225 13.1937 14.1675 11.8587 14.6475C12.2262 13.9725 12.5862 13.1025 12.8412 12.015ZM15.6387 7.4925C15.7587 7.9725 15.8262 8.4825 15.8262 9C15.8262 9.5175 15.7587 10.0275 15.6312 10.515H13.0887C13.1412 10.0425 13.1712 9.5325 13.1712 8.9925C13.1712 8.4525 13.1412 7.965 13.0812 7.4925H15.6387ZM15.0162 5.9925H12.8112C12.5487 4.9125 12.1662 4.0275 11.7987 3.33C13.1637 3.81 14.3037 4.7625 15.0162 5.9925ZM11.2512 5.9925H8.40117C8.82117 4.5375 9.45117 3.525 9.82617 3.0225C10.2012 3.525 10.8312 4.5375 11.2512 5.9925ZM7.85367 3.33C7.48617 4.0275 7.10367 4.9125 6.84117 5.9925H4.63617C5.34867 4.7625 6.48867 3.81 7.85367 3.33Z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgGlobe;
