import WikiTooltip from "./WikiTooltip";
import styles from '../styles/Pill.module.css';
import React from "react";

const Pill = ({preIcon, text, wikiId}) => {
    const displaySpan = (
        <span className={styles.pill}>
            {preIcon && <span className={styles.icon}>{preIcon}</span>}
            <span className={styles.text}>{text}</span>
        </span>
    );
    return wikiId ? (
        <WikiTooltip wikiId={wikiId} alt={text} outerContents={displaySpan} vertical />
    ) : (
        {displaySpan}
    );
}

export default Pill;