
import {Tooltip} from "@frog/babel-street-ds-toolkit";
import { styled } from '@mui/material/styles';


const StyledTooltip = styled((props) => (
  <Tooltip {...props} />
))(({ theme }) => ({
  '&': {
    boxShadow: '0px 2px 1px 0px #0E111733',
    width: 'unset',
  },
}));

export default StyledTooltip;
