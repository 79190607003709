import {useState} from "react";
import styles from "../styles/Switch.module.css";
import classNames from "classnames";
import {Typography} from "@frog/babel-street-ds-toolkit";
import SvgStars from "../icons/SvgStars";

const Switch = ({onChange}) => {
    const [currentState, setCurrentState] = useState(true);
    const toggleState = () => {
        setCurrentState(!currentState);
        onChange && onChange(!currentState);
    }
    return <div className={classNames(styles.switch, {[styles.on]: currentState})} onClick={toggleState}>
        <div className={styles.thumb}>
            <SvgStars />
        </div>
        <div className={styles.track}>
            <Typography variant="button_bold" className={styles.label}>{currentState ? 'on' : 'off'}</Typography>
        </div>
    </div>;
}

export default Switch;