import React, {useState} from 'react';
import styles from '../../styles/PasteText.module.css';
import AnalyzeButton from "./AnalyzeButton";
import {DocumentsIcon, Link, Typography} from "@frog/babel-street-ds-toolkit";
import {useTextAnalytics} from "../../providers/TextAnalyticsProvider";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {Settings} from "../../config";

const CHAR_LIMIT = 10000;

const FONT = {
    fontFamily: 'Noto Sans',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
};

const PasteText = () => {
    const { submittedDoc } = useTextAnalytics();
    const [inputtedText, setInputtedText] = useState(submittedDoc?.content ?? '');
    const [selectedLang, setSelectedLang] = useState(
        submittedDoc?.language && Settings.availableLanguages.includes(submittedDoc.language)
            ? submittedDoc.language
            : 'unknown'
    );

    const limitReached = inputtedText.length >= CHAR_LIMIT;

    return (
        <div className={styles.pageLayout}>
            <AnalyzeButton disabled={inputtedText.trim().length === 0} content={inputtedText} language={selectedLang === 'unknown' ? undefined : selectedLang }/>
            <div style={{ position: 'relative' }}>
                {inputtedText.length === 0 && <>
                    <DocumentsIcon className={styles.docIcon} sx={{ width: '162px', height: '162px' }} />
                    <div className={styles.helperText}>
                        <Typography variant="h6" sx={{ color: 'var(--med-blue)' }}>
                            Unlock deeper insights with language-aware analysis.
                        </Typography>
                        <Typography variant="body1_italic" sx={{ marginTop: '4px' }}>
                            Babel Street Text Analytics automatically detects the language of your text — whether it's Arabic, Chinese, French, or any of the dozens supported — and unlocks capabilities tailored to the unique characteristics of each.
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: '24px' }}>
                            For a full list of supported languages, <Link href="https://docs.babelstreet.com/Intro/en/index-en.html#UUID-23db9198-240f-374b-8d33-bde5308fa8f6"
                                  target="_blank"
                                  sx={{ color: 'var(--dark-blue)' }}
                            >click here</Link>.
                        </Typography>
                        <div style={{ marginTop: '24px' }}>
                            <Typography variant="body2_italic">
                                Please do not include any personal or sensitive information in the text submitted.
                            </Typography>
                        </div>
                    </div>
                </>}
                <textarea
                    dir="auto"
                    className={styles.textArea}
                    placeholder="Type or paste text here"
                    maxLength={CHAR_LIMIT}
                    value={inputtedText}
                    onChange={(e) => setInputtedText(e.target.value)}
                />
            </div>
            <div>
                <Select
                    onChange={(e) => { setSelectedLang(e.target.value); }}
                    value={selectedLang}
                    MenuProps={{ PaperProps: { sx: { '& .MuiMenuItem-root': {...FONT} }}}}
                    sx={{
                        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                        '& .MuiSelect-icon': { color: '#0E111780' },
                        '& .MuiSelect-select': {
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            paddingLeft: '0px',
                            color: '#0E111780',
                            ...FONT,
                        },
                    }}
                >
                    <MenuItem value={'unknown'}>Auto detect language</MenuItem>
                    {Settings.availableLanguages.map(lang => <MenuItem key={lang} value={lang}>{Settings.languages[lang]}</MenuItem>)}
                </Select>
                <div>
                    <Typography variant="body2" style={{ color: limitReached ? 'var(--med-red)' : '#0E111780' }}>
                        {limitReached && <span style={{ marginRight: '16px' }}>Character count limit</span>}
                        {inputtedText.length.toLocaleString()} / {CHAR_LIMIT.toLocaleString()}
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export default PasteText;

