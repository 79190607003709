import { ToggleButton, ToggleButtonGroup, } from '@frog/babel-street-ds-toolkit';
import {useTextAnalytics} from "../providers/TextAnalyticsProvider";

const DocToggle = () => {
    const { libraryOpen, documentType, setDocumentType } = useTextAnalytics();

    const baseStyle = { transition: 'all 0.3s', overflow: 'visible' };
    const collapsedStyle = { ...baseStyle, opacity: 0, transform: 'translateX(-40px)', pointerEvents: 'none' };

    return (
        <div style={libraryOpen ? baseStyle : collapsedStyle}>
            <div style={{ width: '316px', position: 'absolute' }}>
                <ToggleButtonGroup
                    value={[documentType]}
                    exclusive
                    onChange={(_, newDocToggle) => setDocumentType(newDocToggle)}
                    variant="searchBar"
                    style={{ display: 'flex', margin: '0 10px' }}
                >
                    <ToggleButton value={'sampleDoc'} style={{ flex: '1', padding: '8px 0' }}>Sample Docs</ToggleButton>
                    <ToggleButton value={'pasteText'} style={{ flex: '1', padding: '8px 0' }}>Paste Text</ToggleButton>
                </ToggleButtonGroup>
            </div>
        </div>
    );
}

export default DocToggle;
