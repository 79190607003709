import React, {useMemo} from 'react';
import styles from '../styles/Unsupported.module.css';
import {useTextAnalytics} from "../providers/TextAnalyticsProvider";
import {Typography} from "@frog/babel-street-ds-toolkit";

const Unsupported = ({tab, supportedLangs}) => {
    const { submittedDoc } = useTextAnalytics();

    let langString = useMemo(() => {
        switch (supportedLangs.length) {
            case 1: return supportedLangs[0];
            case 2: return `${supportedLangs[0]} and ${supportedLangs[1]}`;
            default:
                const langs = [...supportedLangs];
                langs[langs.length-1] = 'and ' + langs[langs.length-1];
                return langs.join(', ');
        }
    }, [supportedLangs]);

    return (
        <div className={styles.unsupported}>
            <div className={styles.blurEffect}>
                <div className={styles.message}>
                    <Typography variant="body1" style={{ color: '#FCFCFDBF' }}>{tab} are only available in {langString}.</Typography>
                </div>
            </div>
            <div className={styles.blurredDoc}>
                <div style={{paddingTop: '8px', marginBottom: '10px' }} dir="auto">
                    <Typography variant="body2_bold">{submittedDoc?.title || ''}</Typography>
                </div>
                <div className="documentText" dir="auto">{submittedDoc?.content || ''}</div>
            </div>
        </div>
    );
}

export default Unsupported;
