
const SvgFlag = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 3V16.065C3 16.47 3.375 16.7625 3.75 16.755C4.125 16.7475 4.5 16.44 4.5 16.065V10.5H13.86C14.4 10.5 14.76 9.9525 14.55 9.4575L12.81 6.15C12.7725 6.0525 12.7725 5.9475 12.81 5.8575L14.55 2.5425C14.7675 2.0475 14.4 1.5 13.86 1.5H4.5C3.675 1.5 3 2.175 3 3ZM12.18 3C12.45 3 12.63 3.2775 12.525 3.525L11.1825 5.85C11.1375 5.9475 11.1375 6.0525 11.1825 6.15L12.525 8.475C12.63 8.7225 12.45 9 12.18 9H5.25C4.8375 9 4.5 8.6625 4.5 8.25V3.75C4.5 3.3375 4.8375 3 5.25 3H12.18Z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgFlag;
