import React, {createContext, useContext, useMemo, useState} from 'react';
import RestUtils from "../utilities/RestUtils";
import Config, {Features} from '../config';

const {
    OVERVIEW, TOPICS, ENTITIES,
    LEMMAS, PARTS_OF_SPEECH, COMPOUND_COMPONENTS, HAN_READINGS, TOKENIZER, SENTENCE
} = Features;

const config = Config();

const TextAnalyticsContext = createContext(null);

const TextAnalyticsProvider = ({ children }) => {
    // -- Current Screen --
    /** 'sampleDoc' or 'pasteText' */
    const [documentType, _setDocumentType] = useState('sampleDoc');
    /** 'analysis' or 'linguistics' */
    const [tabCategory, setTabCategory] = useState('analysis');
    /** 'overview', 'topics', or 'entities' */
    const [analysisTab, setAnalysisTab] = useState(OVERVIEW);
    /** Whether the left pane is open or collapsed */
    const [libraryOpen, setLibraryOpen] = useState(true);
    /** 'lemmas', 'partsOfSpeech', 'compoundComponents', 'hanReadings', 'tokenizer', 'sentence' */
    const [linguisticTab, setLinguisticTab] = useState(LEMMAS);
    const setDocumentType = (newDocType) => {
        _setDocumentType(newDocType);
        _setChosenArticleId(undefined);
        setWasDocAnalyzed(false);
        resetLoadedData();
    }

    // -- Preset Article List --
    const [presetArticles, setPresetArticles] = useState([]);
    const [chosenArticleId, _setChosenArticleId] = useState(undefined);
    const currentArticle = useMemo(() => {
        return presetArticles.find(a => a.id === chosenArticleId);
    }, [presetArticles, chosenArticleId]);
    const loadPresetArticles = () => {
        RestUtils.get(config.API_LIBRARY)
            .then((res) => setPresetArticles(res.data.result))
            .catch(() => setPresetArticles([]));
    };
    const setChosenArticleId = (articleId) => {
        _setChosenArticleId(articleId);
        _setDocumentType('sampleDoc');
        setWasDocAnalyzed(false);
        resetLoadedData();
    }

    // -- Analyzing --
    const [wasDocAnalyzed, setWasDocAnalyzed] = useState(false);
    const [submittedDoc, setSubmittedDoc] = useState(undefined);
    const analyzeDoc = (content, language, wasLangDetected) => {
        const doc = { content, language, wasLangDetected };
        if (doc.content == null || doc.content.trim() === '') {
            console.error("Article was not chosen.");
            return; // Should never come up.
        }
        setSubmittedDoc({
            ...doc,
            ...(documentType === 'sampleDoc' ? {
                category: currentArticle?.category,
                title: currentArticle?.title
            } : {}),
        });
        setWasDocAnalyzed(true);
        setLibraryOpen(false);
    }
    const getData = async (data, setFn, endpoint) => {
        if (!submittedDoc?.content || !submittedDoc?.language) {
            console.error("Document was not yet analyzed.");
            return null; // Should never come up.
        }

        // Early return if we've already fetched this data.
        if (data != null) {
            return {...submittedDoc, results: data};
        }

        // Otherwise, fetch from the server
        return RestUtils.post(endpoint, {
            content: submittedDoc.content,
            language: submittedDoc.language,
        }).then((res) => {
            setFn(res.data.result);
            return {...submittedDoc, results: res.data.result};
        });
    }
    const getPageDataFor = async (feature) => {
        switch (feature) {
            case OVERVIEW: return getData(overviewData, setOverviewData, config.API_OVERVIEW);
            case TOPICS:   return getData(topicData,    setTopicData,    config.API_TOPICS);
            case ENTITIES: return getData(entityData,   setEntityData,   config.API_ENTITIES);
            case SENTENCE: return getData(sentenceData, setSentenceData, config.API_SENTENCES);
            // The rest of these all use the same "linguistics data"
            case LEMMAS:
            case PARTS_OF_SPEECH:
            case COMPOUND_COMPONENTS:
            case HAN_READINGS:
            case TOKENIZER:
                return getData(linguisticsData, setLinguisticsData, config.API_LINGUISTICS);
            default:
                throw new Error("Unrecognized feature: " + feature);
        }
    }

    // -- Returned Data --
    const [overviewData, setOverviewData] = useState(null);
    const [topicData, setTopicData] = useState(null);
    const [entityData, setEntityData] = useState(null);
    const [linguisticsData, setLinguisticsData] = useState(null);
    const [sentenceData, setSentenceData] = useState(null);
    const resetLoadedData = () => {
        setOverviewData(null);
        setTopicData(null);
        setEntityData(null);
        setLinguisticsData(null);
        setSentenceData(null);
    }

    // -- Miscellaneous --
    const detectLanguage = async (content) => {
        return RestUtils
            .post(config.API_LANGUAGE, { content })
            .then((res) => res.data.result);
    }
    const [wikiData, setWikiData] = useState({});
    const getWikiData = async (qid) => {
        if (qid in wikiData) {
            return wikiData[qid];
        }

        return RestUtils
            .get(config.API_WIKI + '/' + qid)
            .then((res) => {
                setWikiData({ ...wikiData, [qid]: res.data.result });
                return res.data.result;
            });
    }

    return (
        <TextAnalyticsContext.Provider value={{
            // -- Current Screen --
            documentType, setDocumentType,
            tabCategory, setTabCategory,
            analysisTab, setAnalysisTab,
            linguisticTab, setLinguisticTab,
            libraryOpen, setLibraryOpen,
            // -- Preset Article List --
            presetArticles,
            chosenArticleId, setChosenArticleId,
            currentArticle,
            loadPresetArticles,
            // -- Analyzing --
            wasDocAnalyzed,
            analyzeDoc,
            submittedDoc,
            // -- Result Data Loading --
            getPageDataFor,
            // -- Misc --
            detectLanguage,
            getWikiData,
        }}>
            {children}
        </TextAnalyticsContext.Provider>
    );
}

export const useTextAnalytics = () => useContext(TextAnalyticsContext);
export default TextAnalyticsProvider;
