import React, {useMemo} from 'react';
import ResultLayout from "../ResultLayout";
import LanguageLabel from "../LanguageLabel";
import {LinkIcon, Typography} from "@frog/babel-street-ds-toolkit";
import ResultHeading from "../ResultHeading";
import {getAnnotatedSpans} from "../../utilities/AnnotationUtils";
import {Settings} from "../../config";
import Pill from "../Pill";

const Topics = ({data, supportedLangs}) => {
    const keyphrases = useMemo(() => data?.results?.attributes?.keyphrases?.items ?? [], [data]);
    const concepts = useMemo(() => data?.results?.attributes?.concepts?.items ?? [], [data]);

    const docSpans = useMemo(() => {
        const orig = data?.content || '';

        // Convert each phrase to its range in the original content
        const ranges = keyphrases
            .flatMap(phrase => phrase.extents.map(offset => ({start: offset.startOffset, end: offset.endOffset})))
            .sort((a, b) => a.start - b.start)
            .map(r => ({ ...r, props: { annotationType: 'topic', primaryColor: '#A2D6FF' } }));

        // Early escape when there are no found keyphrases.
        if (ranges.length === 0) {
            return [<span key="full-text">{orig}</span>];
        }

        // Create our displayed spans
        return getAnnotatedSpans(orig, ranges);
    }, [data, keyphrases]);

    return <ResultLayout
        addSeparator
        unsupported={supportedLangs.includes(data.language) ? false : {
            tab: 'Topics',
            supportedLangs: supportedLangs.map(langCode => Settings.languages[langCode])
        }}
        header={
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ResultHeading
                    heading="Topics"
                    subheading="Provides a high-level understanding of the main topics discussed in provided text by identifying both explicit keyphrases and implicit concepts, which may not be directly mentioned but are implied by the content."
                />
                {concepts && concepts.length > 0 && (
                    <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
                        {concepts.map(c => <Pill key={c.phrase} preIcon={<LinkIcon />} wikiId={c.conceptId} text={c.phrase} />)}
                    </div>
                )}
                <LanguageLabel wasDetected={data.wasLangDetected} languageCode={data.language} />
            </div>
        }
        contents={<>
            <div style={{ paddingTop: '8px', marginBottom: '10px' }} dir="auto">
                <Typography variant="body2_bold">{data?.title || ''}</Typography>
            </div>
            <div className="documentText" dir="auto">{docSpans}</div>
        </>}
    ></ResultLayout>
}

export default Topics;
