import React, {useEffect} from 'react';

import styles from '../styles/Library.module.css';
import { Categories } from '../config';
import {useTextAnalytics} from "../providers/TextAnalyticsProvider";
import {ChevronCloseLeftIcon, Tag, Typography} from "@frog/babel-street-ds-toolkit";
import classNames from "classnames";

const Library = () => {
    const {
        presetArticles,
        loadPresetArticles,
        documentType,
        chosenArticleId,
        setChosenArticleId,
        libraryOpen,
        setLibraryOpen
    } = useTextAnalytics();

    const isDisabled = documentType !== 'sampleDoc';

    // On initial load, fetch our library of predefined articles
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => loadPresetArticles(), []);

    const chooseSampleDoc = (id) => {
        if (isDisabled || chosenArticleId === id) return;
        setChosenArticleId(id);
    }

    return (
        // To get the library to collapse & expand cleanly, there's a bit of absolute / manual positioning with the
        // three "columns" that make it up: the left-side gray bar, the right side "collapse" bar, and the library list
        // that sits between them. If you ever change this code, the main complications to be aware of are:
        //   - putting the arrow in the left border when collapsed
        //   - not squishing the document titles as it disappears
        <div className={classNames(styles.libLayout, {[styles.libraryOpen]: libraryOpen})}>
            {/* Left-side gray bar - just for appearance. */}
            <div className={styles.beam}></div>
            {/* Right-side gray bar - Used to collapse/expand the library. */}
            <div className={classNames(styles.beam, styles.closeColumn)} onClick={() => setLibraryOpen(!libraryOpen)} >
                <ChevronCloseLeftIcon />
            </div>
            {/* This is the actual document list. */}
            <div className={styles.libraryList}>
                {presetArticles.map((preset) => (
                    <div key={`story${preset.id}`}
                         onClick={() => chooseSampleDoc(preset.id)}
                         className={classNames({
                             [styles.libEntry]: true,
                             [styles.selected]: chosenArticleId === preset.id,
                             [styles.disabled]: isDisabled,
                         })}
                    >
                        <Typography variant="body2_bold" className={styles.docTitle} style={{marginBottom: '4px'}}>
                            {preset.title}
                        </Typography>

                        <Tag colorStyle="primary"
                             disableTruncation
                             className={styles.catLabel}
                             text={Categories[preset.category]}
                             variant="outline"/>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Library;