import React, {Fragment, useMemo} from 'react';
import ResultLayout from "../ResultLayout";
import ResultHeading from "../ResultHeading";
import LanguageLabel from "../LanguageLabel";
import {Typography} from "@frog/babel-street-ds-toolkit";
import Grid from '@mui/material/Grid';
import {Settings} from "../../config";

const Tokenizer = ({data, supportedLangs}) => {
    const tokens = useMemo(() => data?.results?.map(t => t.text) ?? [], [data]);

    return <ResultLayout
        addSeparator
        unsupported={supportedLangs.includes(data.language) ? false : {
            tab: 'Tokens',
            supportedLangs: supportedLangs.map(langCode => Settings.languages[langCode])
        }}
        header={
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <ResultHeading
                    heading="Tokenizer"
                    subheading={`Returns a list of tokens for a given string. A token is an atomic element such as a word, number, possessive affix, or punctuation. (${supportedLangs.length} Supported Languages)`}
                />
                <LanguageLabel wasDetected={data.wasLangDetected} languageCode={data.language}/>
            </div>
        }
        contents={<>
            <div style={{paddingTop: '8px', marginBottom: '10px'}} dir="auto">
                <Typography variant="body2_bold">{data?.title || ''}</Typography>
            </div>
            <Grid container spacing={0} dir="auto">
                {tokens.map((t, i) => {
                    return (
                        <Grid item xs={2} key={`token-${i}`}>
                            <span className="documentText">{t}</span>
                        </Grid>
                    );
                })}
            </Grid>
        </>}
    ></ResultLayout>
}

export default Tokenizer;
