import React, {useEffect, useState} from 'react';
import {useTextAnalytics} from "../providers/TextAnalyticsProvider";
import {SupportedLanguages} from "../config";
import {Button, Progress, Typography} from "@frog/babel-street-ds-toolkit";
import FullPageInfo from "./FullPageInfo";

const DataLoader = ({ResultComponent, feature}) => {
    const { getPageDataFor } = useTextAnalytics();
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);

    // On initial load, fetch this page's data
    useEffect(() => {
        getPageDataFor(feature)
            .then((data) => setData(data))
            .catch(() => setError(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return error ? (
            // -- Error Page --
            <FullPageInfo contents={<>
                <Typography variant="h4" sx={{ color: 'var(--med-blue)', marginBottom: '10px' }}>Oops</Typography>
                <Typography variant="h6">Results failed to load.<br />Try reloading to view results.</Typography>
                <Button color="primary"
                    size="medium"
                    variant="contained"
                    onClick={() => window.location.reload()}
                    style={{
                        boxShadow: '0px 4px 5px 0px #0E111740',
                        margin: '18px auto 0 auto',
                        width: '153px',
                    }}>
                    Reload
                </Button>
            </>} />
        ) : (data == null) ? (
            // -- Loading Page --
            <FullPageInfo contents={<>
                <Typography variant="h6">Loading...</Typography>
                <Progress color="primary" isLinear variant="indeterminate" sx={{ margin: '10px auto' }}/>
            </>} />
        ) : (
            // -- Results Page --
            <ResultComponent data={data} supportedLangs={SupportedLanguages[feature] || []} />
        );
}

export default DataLoader;
