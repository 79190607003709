import React, {useMemo} from 'react';
import {Categories, Sentiments} from "../../config";
import ResultLayout from "../ResultLayout";
import LanguageLabel from "../LanguageLabel";
import {Typography} from "@frog/babel-street-ds-toolkit";
import StyledChip from "../StyledChip";
import ResultHeading from "../ResultHeading";

const Overview = ({data}) => {
    const category = useMemo(() => {
        const catCode = data?.category ?? data?.results?.category?.label
        return catCode && Categories[catCode];
    }, [data]);

    const sentiment = useMemo(() => {
        const sentCode = data?.results?.sentiment?.document?.label;
        return sentCode && Sentiments[sentCode];
    }, [data]);

    return <ResultLayout
        addSeparator
        header={
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ResultHeading
                    heading="Overview"
                    subheading={<>
                        <span style={{ fontWeight: 'bold' }}>Category</span> automatically classifies your text into predefined
                        topics (like Business, Technology, or Entertainment), while <span style={{ fontWeight: 'bold' }}>Sentiment</span> determines the emotional tone (positive, negative, or neutral). These provide a high-level understanding of your text and, when scaled to millions of documents, identify trends and uncover hidden patterns.
                    </>} />
                {(category || sentiment) && (
                    <div style={{ display: 'flex', gap: '4px' }}>
                        {category && <StyledChip label={<><span style={{ color: '#101A24' }}>Category:</span> {category}</>} />}
                        {sentiment && <StyledChip label={<><span style={{ color: '#101A24' }}>Sentiment:</span> {sentiment}</>} />}
                    </div>
                )}
                <LanguageLabel wasDetected={data.wasLangDetected} languageCode={data.language} />
            </div>
        }
        contents={
            <>
                <div style={{ paddingTop: '8px', marginBottom: '10px' }} dir="auto">
                    <Typography variant="body2_bold">{data?.title || ''}</Typography>
                </div>
                <div className="documentText" dir="auto">{data?.content || ''}</div>
            </>
        }
    ></ResultLayout>
}

export default Overview;
