import React from 'react';
import {Typography} from "@frog/babel-street-ds-toolkit";

const ResultHeading = ({heading, subheading}) => {
    return (
        <div>
            <Typography variant="body1_bold" style={{ display: 'block' }}>{heading}</Typography>
            <Typography variant="body1" style={{ display: 'block', color: '#0E111780' }}>{subheading}</Typography>
        </div>
    );
}

export default ResultHeading;
