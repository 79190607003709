import React, {Fragment, useMemo} from 'react';
import styles from '../../styles/Sentence.module.css';
import ResultLayout from "../ResultLayout";
import ResultHeading from "../ResultHeading";
import LanguageLabel from "../LanguageLabel";
import {Typography} from "@frog/babel-street-ds-toolkit";
import {Settings} from "../../config";

const Sentence = ({data, supportedLangs}) => {
    const sentences = useMemo(() => data?.results?.sentences ?? [], [data]);

    return <ResultLayout
        addSeparator
        unsupported={supportedLangs.includes(data.language) ? false : {
            tab: 'Sentences',
            supportedLangs: supportedLangs.map(langCode => Settings.languages[langCode])
        }}
        header={
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <ResultHeading
                    heading="Sentence"
                    subheading={`Parses a given text into sentences. (${supportedLangs.length} Supported Languages)`}
                />
                <LanguageLabel wasDetected={data.wasLangDetected} languageCode={data.language}/>
            </div>
        }
        contents={<>
            <div style={{paddingTop: '8px', marginBottom: '10px'}} dir="auto">
                <Typography variant="body2_bold">{data?.title || ''}</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
                {sentences.map((s, i) => <Fragment key={`sentence-${i}`}>
                    <div className={styles.sentence} dir="auto">
                        <Typography variant="body2">{s}</Typography>
                    </div>
                    <div className={styles.divider} />
                </Fragment>)}
            </div>
        </>}
    ></ResultLayout>
}

export default Sentence;
