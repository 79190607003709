import React, {Fragment, useCallback, useMemo, useState} from 'react';
import styles from '../../styles/Entities.module.css';
import ResultLayout from "../ResultLayout";
import ResultHeading from "../ResultHeading";
import LanguageLabel from "../LanguageLabel";
import {
    BusinessIcon, Button,
    EmailIcon,
    LinkIcon,
    PeopleIcon,
    PlaceOutlineIcon,
    ProfileIcon,
    Typography
} from "@frog/babel-street-ds-toolkit";
import {getAnnotatedSpans} from "../../utilities/AnnotationUtils";
import AnnotatedSpan from "../AnnotatedSpan";
import {onlyUnique} from "../../utilities/ArrayUtils";
import SvgFlag from "../../icons/SvgFlag";
import SvgPhone from "../../icons/SvgPhone";
import SvgVerified from "../../icons/SvgVerified";
import SvgMoney from "../../icons/SvgMoney";
import SvgCreditCard from "../../icons/SvgCreditCard";
import SvgShop from "../../icons/SvgShop";
import SvgInfinity from "../../icons/SvgInfinity";
import SvgDownUpArrows from "../../icons/SvgDownUpArrows";
import SvgGlobe from "../../icons/SvgGlobe";
import SvgCalendar from "../../icons/SvgCalendar";
import {Settings} from "../../config";

const RED    = {light: '#FCFCFDBF', dark: '#CC1A4C'};
const ORANGE = {light: '#FCFCFDBF', dark: '#CD6600'};
const GREEN  = {light: '#FCFCFDBF', dark: '#338331'};
const PURPLE = {light: '#FCFCFDBF', dark: '#6401A0'};
const BLUE   = {light: '#FCFCFDBF', dark: '#388CCD'};

const CategoryColors = {
    // Red
    'TITLE': {...RED, icon: <ProfileIcon />},
    'PERSON': {...RED, icon: <PeopleIcon />},
    'NATIONALITY': {...RED, icon: <SvgFlag />},
    // Orange
    'IDENTIFIER:EMAIL': {...ORANGE, icon: <EmailIcon />},
    'IDENTIFIER:PHONE_NUMBER': {...ORANGE, icon: <SvgPhone />},
    'IDENTIFIER:PERSONAL_ID_NUM': {...ORANGE, icon: <SvgVerified />},
    // Green
    'IDENTIFIER:MONEY': {...GREEN, icon: <SvgMoney />},
    'IDENTIFIER:CREDIT_CARD_NUM': {...GREEN, icon: <SvgCreditCard />},
    'PRODUCT': {...GREEN, icon: <SvgShop />},
    // Purple
    'ORGANIZATION': {...PURPLE, icon: <BusinessIcon/>},
    'RELIGION': {...PURPLE, icon: <SvgInfinity />},
    // Blue
    'LOCATION': {...BLUE, icon: <PlaceOutlineIcon/>},
    'IDENTIFIER:DISTANCE': {...BLUE, icon: <SvgDownUpArrows />},
    'IDENTIFIER:URL': {...BLUE, icon: <SvgGlobe />},
    'TEMPORAL:DATE': {...BLUE, icon: <SvgCalendar />}, // We also turn 'TEMPORAL:TIME' into 'TEMPORAL:DATE'

    // -- Unused categories --
    // 'ACTIVITY'
    // 'ANATOMY'
    // 'DISEASE'
    // 'EVENT'
    // 'FOOD'
    // 'LANGUAGE'
    // 'MEASURE'
    // 'SPECIES'
    // 'SUBSTANCE'
    // 'TRANSPORT'
    // 'MISC'
    // 'IDENTIFIER'
    // 'NUMBER'
};

// Each outer array is a column, each inner array is a category in that column.
const drawerCategories = [
    [
        { key: 'TITLE', text: 'Title' },
        { key: 'PERSON', text: 'Person' },
        { key: 'NATIONALITY', text: 'Nationality' },
    ],
    [
        { key: 'IDENTIFIER:EMAIL', text: 'Email' },
        { key: 'IDENTIFIER:PHONE_NUMBER', text: 'Phone Number' },
        { key: 'IDENTIFIER:PERSONAL_ID_NUM', text: 'ID Number' },
    ],
    [
        { key: 'IDENTIFIER:MONEY', text: 'Money' },
        { key: 'IDENTIFIER:CREDIT_CARD_NUM', text: 'Credit Card' },
        { key: 'PRODUCT', text: 'Product' },
    ],
    [
        { key: 'ORGANIZATION', text: 'Organization' },
        { key: 'RELIGION', text: 'Religion' },
    ],
    [
        { key: 'LOCATION', text: 'Location' },
        { key: 'IDENTIFIER:DISTANCE', text: 'Distance' },
        { key: 'IDENTIFIER:URL', text: 'Web Address' },
        { key: 'TEMPORAL:DATE', text: 'Date/Time' },
    ],
];

const Entities = ({data, supportedLangs}) => {
    const [drawerOpen, setDrawerOpen] = useState(true);

    // -- Response Data Parsing --
    const entities = useMemo(() => {
        return (Array.isArray(data?.results) ? data.results : [])
            // Change from an array of categories (each containing an entities array) to a flattened array of entities.
            .flatMap(r => r.entities)
            .map(e => {
                // Date and time are treated as the same category, so change "time" to "date".
                if (e.type === 'TEMPORAL:TIME') {
                    e.type = 'TEMPORAL:DATE';
                }
                return e;
            })
            // Only keep the ones that have categories we care about.
            .filter(r => r.type in CategoryColors)
            // Each entity can have multiple occurrences. Flatten those occurrences to be separate array items.
            .flatMap(e => e.mentionOffsets.map(mo => ({ ...e, mentionOffsets: mo })))
            // Sort by their start offset to be compatible with our annotation utilities.
            .sort((a, b) => a.mentionOffsets.startOffset - b.mentionOffsets.startOffset);
    }, [data]);

    // -- Category Data / Methods --
    const [enabledCategories, setEnabledCategories] = useState(Object.keys(CategoryColors));
    const availableCategories = useMemo(() => entities.map(e => e.type).filter(onlyUnique), [entities]);
    const toggleCategory = useCallback((categoryId) => {
        const catIndex = enabledCategories.indexOf(categoryId);
        setEnabledCategories(catIndex === -1
            ? [...enabledCategories, categoryId] // Add the category
            : enabledCategories.filter(c => c !== categoryId)); // Remove the category
    }, [enabledCategories]);
    const toggleAllCategories = () => {
        const allCategories = Object.keys(CategoryColors);
        setEnabledCategories(enabledCategories.length < allCategories.length ? allCategories : []);
    }

    /** An array of spans representing the displayed document with annotations. */
    const docSpans = useMemo(() => {
        const orig = data?.content || '';
        const annotations = entities.map(e => {
            return {
                start: e.mentionOffsets.startOffset,
                end: e.mentionOffsets.endOffset,
                disabled: !enabledCategories.includes(e.type),
                props: {
                    annotationType: 'entity',
                    primaryColor: CategoryColors[e.type].dark,
                    secondaryColor: CategoryColors[e.type].light,
                    isActive: true,
                    preIcon: CategoryColors[e.type].icon,
                    // Add the "link" capabilities if needed:
                    ...(!e.entityId || !e.entityId.startsWith("Q") ? {} : {
                        postIcon: <LinkIcon />,
                        wikiId: e.entityId,
                    })
                }
            };
        });
        return getAnnotatedSpans(orig, annotations);
    }, [data, entities, enabledCategories]);


    const getSpan = useCallback((categoryKey, text) => {
        const category = CategoryColors[categoryKey];
        const style = !enabledCategories.includes(categoryKey)
            ? {fgColor: category.dark, borderColor: category.dark}
            : {bgColor: category.dark, fgColor: category.light};
        const isDisabled = !availableCategories.includes(categoryKey);
        return (
            <Fragment key={categoryKey}>
                <AnnotatedSpan
                    annotationType="entity"
                    primaryColor={category.dark}
                    secondaryColor={category.light}
                    disabled={isDisabled}
                    isActive={enabledCategories.includes(categoryKey)}
                    tooltip={isDisabled ? "This entity type was not found in the document." : undefined}
                    text={text}
                    preIcon={category.icon}
                    onClick={() => toggleCategory(categoryKey)}
                    {...style}
                />
            </Fragment>
        );
    }, [availableCategories, enabledCategories, toggleCategory]);

    return <ResultLayout
        addSeparator
        unsupported={supportedLangs.includes(data.language) ? false : {
            tab: 'Entities',
            supportedLangs: supportedLangs.map(langCode => Settings.languages[langCode])
        }}
        header={
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <ResultHeading
                    heading="Entities"
                    subheading="Named entities include names of people, organizations, and locations. This feature identifies and extracts these entities from text and links them to external schema, including Wikidata, DBpedia ontology, and Refinitiv PermID."
                />
                <LanguageLabel wasDetected={data.wasLangDetected} languageCode={data.language}/>
            </div>
        }
        contents={<>
            <div style={{paddingTop: '8px', marginBottom: '10px'}} dir="auto">
                <Typography variant="body2_bold">{data?.title || ''}</Typography>
            </div>
            <div className="documentText" dir="auto">{docSpans}</div>
        </>}
        drawer={{
            isOpen: drawerOpen,
            setOpen: (isOpen) => setDrawerOpen(isOpen),
            title: "View/Select Entities",
            content: (<>
                <div className={styles.drawerFlex}>
                    {drawerCategories.map((catArr, i) => (
                        <div key={`catColumn-${i}`}>{catArr.map(cat => {
                            return getSpan(cat.key, cat.text);
                        })}</div>
                    ))}
                </div>
                <Button color="primary" size="medium" variant="ghost" style={{ position: 'absolute', bottom: '10px' }} onClick={toggleAllCategories}>
                    Select/Unselect All
                </Button>
            </>),
        }}
    ></ResultLayout>
}

export default Entities;
