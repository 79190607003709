import styles from '../styles/FullPageInfo.module.css';

const FullPageInfo = ({contents}) => {

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.pageContents}>
                {contents}
            </div>
        </div>
    );
}

export default FullPageInfo;