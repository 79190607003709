import styles from '../styles/CategoryTabs.module.css';
import {useTextAnalytics} from "../providers/TextAnalyticsProvider";
import {ControlsIcon, TranslateIcon} from "@frog/babel-street-ds-toolkit";
import classNames from "classnames";

const CategoryTabs = () => {
    const { tabCategory, setTabCategory } = useTextAnalytics();

    return (
        <div className={styles.categoryTabs}>
            <div className={classNames(styles.toggle, { [styles.selected]: tabCategory === 'analysis' })}
                onClick={() => setTabCategory('analysis')} >
                <ControlsIcon />
            </div>
            <div className={classNames(styles.toggle, { [styles.selected]: tabCategory === 'linguistics' })}
                 onClick={() => setTabCategory('linguistics')} >
                <TranslateIcon />
            </div>
        </div>
    );
}

export default CategoryTabs;
