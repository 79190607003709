export const Settings = {
    'maxChars': 10000,
    'languages': {
        'sqi': 'Albanian',
        'ara': 'Arabic',
        'ben': 'Bengali',
        'bul': 'Bulgarian',
        'cat': 'Catalan',
        'zho': 'Chinese',
        'hrv': 'Croatian',
        'ces': 'Czech',
        'dan': 'Danish',
        'nld': 'Dutch',
        'eng': 'English',
        'est': 'Estonian',
        'fin': 'Finnish',
        'fra': 'French',
        'deu': 'German',
        'ell': 'Greek',
        'guj': 'Gujarati',
        'heb': 'Hebrew',
        'hin': 'Hindi',
        'hun': 'Hungarian',
        'isl': 'Icelandic',
        'ind': 'Indonesian',
        'ita': 'Italian',
        'jpn': 'Japanese',
        'kan': 'Kannada',
        'kor': 'Korean',
        'qkp': 'Korean-North',
        'qkr': 'Korean-South',
        'kur': 'Kurdish',
        'lav': 'Latvian',
        'lit': 'Lithuanian',
        'mkd': 'Macedonian',
        'msa': 'Malay',
        'zsm': 'Malay, Standard',
        'mal': 'Malayalam',
        'nor': 'Norwegian',
        'nob': 'Norwegian-Bokmål',
        'nno': 'Norwegian-Nynorsk',
        'pus': 'Pashto',
        'fas': 'Persian',
        'prs': 'Persian-Afghan',
        'pes': 'Persian-Iranian',
        'pol': 'Polish',
        'por': 'Portuguese',
        'ron': 'Romanian',
        'rus': 'Russian',
        'srp': 'Serbian',
        'slk': 'Slovak',
        'slv': 'Slovenian',
        'som': 'Somali',
        'spa': 'Spanish',
        'swe': 'Swedish',
        'tgl': 'Tagalog',
        'tam': 'Tamil',
        'tel': 'Telugu',
        'tha': 'Thai',
        'tur': 'Turkish',
        'ukr': 'Ukrainian',
        'urd': 'Urdu',
        'uzb': 'Uzbek',
        'vie': 'Vietnamese'
    },
    'availableLanguages' : [
        'ara', 'zho', 'eng', 'deu', 'fra', 'heb', 'hun', 'jpn', 'kor', 'spa', 'por', 'tgl', 'rus', 'vie'
    ],
};

export const Features = {
    OVERVIEW: 'overview',
    TOPICS: 'topics',
    ENTITIES: 'entities',
    LEMMAS: 'lemmas',
    PARTS_OF_SPEECH: 'partsOfSpeech',
    COMPOUND_COMPONENTS: 'compoundComponents',
    HAN_READINGS: 'hanReadings',
    TOKENIZER: 'tokenizer',
    SENTENCE: 'sentence',
};

export const SupportedLanguages = {
    // '/topics/supported-languages',
    topics: ['eng'],
    // '/entities/supported-languages',
    entities: [ 'ara', 'deu', 'eng', 'fas', 'fra', 'heb', 'hun', 'ind', 'ita', 'jpn', 'jpn', 'jpn', 'jpn', 'kor', 'kor', 'msa', 'nld', 'por', 'pus', 'rus', 'spa', 'swe', 'tgl', 'urd', 'vie', 'zho', 'zhs', 'zht', 'zsm', 'uen' ],
    // '/morphology/parts-of-speech/supported-languages',
    partsOfSpeech: ['ara', 'zho', 'ces', 'nld', 'eng', 'fra', 'deu', 'ell', 'heb', 'hun', 'ind', 'ita', 'jpn', 'kor', 'qkp', 'qkr', 'zsm', 'fas', 'prs', 'pes', 'pol', 'por', 'rus', 'spa', 'tgl', 'urd'],
    // '/morphology/lemmas/supported-languages',
    lemmas: ['ara', 'cat', 'zho', 'ces', 'dan', 'nld', 'eng', 'est', 'fra', 'deu', 'ell', 'heb', 'hun', 'ind', 'ita', 'jpn', 'kor', 'qkp', 'qkr', 'lav', 'zsm', 'nor', 'nob', 'nno', 'fas', 'prs', 'pes', 'pol', 'por', 'ron', 'rus', 'srp', 'slk', 'spa', 'swe', 'tgl', 'tha', 'tur'],
    // '/morphology/compound-components/supported-languages',
    compoundComponents: ['dan', 'nld', 'deu', 'hun', 'nor', 'nob', 'nno', 'swe'],
    // '/morphology/han-readings/supported-languages',
    hanReadings: ['zho', 'jpn'],
    // '/tokens/supported-languages',
    tokenizer: ['ara', 'cat', 'ces', 'dan', 'deu', 'ell', 'eng', 'uen', 'est', 'fas', 'fin', 'fra', 'heb', 'hun', 'ind', 'ita', 'jpn', 'jpn', 'jpn', 'jpn', 'kor', 'lav', 'nld', 'nno', 'nob', 'nor', 'pes', 'pol', 'por', 'prs', 'pus', 'qkp', 'qkr', 'ron', 'rus', 'srp', 'slk', 'spa', 'swe', 'tgl', 'tha', 'tur', 'ukr', 'urd', 'zho', 'zsm'],
    // '/sentences/supported-languages',
    sentence: [ 'ara', 'cat', 'ces', 'dan', 'deu', 'ell', 'eng', 'uen', 'est', 'fas', 'fin', 'fra', 'heb', 'hun', 'ind', 'ita', 'jpn', 'jpn', 'jpn', 'jpn', 'kor', 'lav', 'nld', 'nno', 'nob', 'nor', 'pes', 'pol', 'por', 'prs', 'pus', 'qkp', 'qkr', 'ron', 'rus', 'srp', 'slk', 'spa', 'swe', 'tgl', 'tha', 'tur', 'ukr', 'urd', 'zho', 'zsm' ],
}

export const Categories = {
    'ARTS_AND_ENTERTAINMENT': 'Arts & Entertainment',
    'AUTOMOTIVE': 'Automotive',
    'BUSINESS': 'Business',
    'CAREERS': 'Careers',
    'EDUCATION': 'Education',
    'FAMILY_AND_PARENTING': 'Family & Parenting',
    'FOOD_AND_DRINK': 'Food & Drink',
    'HEALTH_AND_FITNESS': 'Health & Fitness',
    'HOBBIES_AND_INTERESTS': 'Hobbies & Interests',
    'HOME_AND_GARDEN': 'Home & Garden',
    'LAW_GOVERNMENT_AND_POLITICS': 'Politics',
    'PERSONAL_FINANCE': 'Personal Finance',
    'PETS': 'Pets',
    'REAL_ESTATE': 'Real Estate',
    'RELIGION_AND_SPIRITUALITY': 'Religion & Spirituality',
    'SCIENCE': 'Science',
    'SOCIETY': 'Society',
    'SPORTS': 'Sports',
    'STYLE_AND_FASHION': 'Style & Fashion',
    'TECHNOLOGY_AND_COMPUTING': 'Technology & Computing',
    'TRAVEL': 'Travel'
};

export const Sentiments = {
    'neg': 'Negative',
    'neu': 'Neutral',
    'pos': 'Positive'
};

const API = () => {
    let API_HOST;
    let API_PORT = '8081';
    let env = process.env.NODE_ENV; // This can be development/production only

    switch (env) {
        case 'development':
            API_HOST = 'http://localhost:' + API_PORT + '/api/';
            break;
        case 'production':
            API_HOST = 'https://ta.demo.babelstreet.com/api/';
            break;
        default:
            API_HOST = 'http://localhost:' + API_PORT + '/api/';
    }

    return {
        'API_HOST': API_HOST,
        'API_LIBRARY': API_HOST + 'library',
        'API_LANGUAGE': API_HOST + 'language',
        'API_OVERVIEW': API_HOST + 'overview',
        'API_TOPICS': API_HOST + 'topics',
        'API_ENTITIES': API_HOST + 'entities',
        'API_LINGUISTICS': API_HOST + 'linguistics',
        'API_SENTENCES': API_HOST + 'sentences',
        'API_WIKI': API_HOST + 'wiki',
        'API_TEXT': API_HOST + 'text',
    }
}
export default API;
