
const SvgPhone = () => {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.2065 2.25H5.70654C4.88154 2.25 4.20654 2.925 4.20654 3.75V14.25C4.20654 15.075 4.88154 15.75 5.70654 15.75H13.2065C14.0315 15.75 14.7065 15.075 14.7065 14.25V3.75C14.7065 2.925 14.0315 2.25 13.2065 2.25ZM13.2065 11.0625C13.2065 11.2725 13.0415 11.4375 12.8315 11.4375H6.08154C5.87154 11.4375 5.70654 11.2725 5.70654 11.0625V4.125C5.70654 3.915 5.87154 3.75 6.08154 3.75H12.8315C13.0415 3.75 13.2065 3.915 13.2065 4.125V11.0625ZM10.6265 13.5525C10.6265 14.1975 10.1015 14.7225 9.45654 14.7225C8.81154 14.7225 8.28654 14.1975 8.28654 13.5525C8.28654 12.9075 8.81154 12.3825 9.45654 12.3825C10.1015 12.3825 10.6265 12.9075 10.6265 13.5525Z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgPhone;
